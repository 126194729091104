.phases-dialog___wrapper {
  width: 60%;
  background-color: var(--pure);
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5rem;
  padding-bottom: 5rem;
  position: relative;
}
.phases-dialog___container {
  width: 70%;
}
.phases-dialog___heading {
  color: var(--sidebar_secondory);
  font-weight: 500;
  text-align: center;
  font-size: 1.1rem;
}
.phases-dialog___text {
  color: var(--paragraphText);
  opacity: 0.7;
  font-weight: bold;
  text-align: center;
  margin-top: 0.5rem;
}
.phases-dialog___button {
  width: 30%;
  padding: 1rem;
  font-size: 1.4rem;
  color: var(--sidebar_secondory);
  border: none;
  cursor: pointer;
  margin-top: 1rem;
}
.phases-dialog___header {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}
.phases-dialog___left {
  border-top-left-radius: 1rem;
  border-end-start-radius: 1rem;
  font-size: 1.2rem;
}
.phases-dialog___Act {
  border-top-right-radius: 1rem;
  border-end-end-radius: 1rem;
  background: var(--sidebar_secondory);
  color: var(--pure);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
}
.phases-dialog___header__icon {
  color: var(--orange);
  margin-left: 1rem;
  font-weight: bold;
}
.phases-dialog___calender__body {
  display: flex;
  justify-content: end;
  position: relative;
}
.phases__calender {
  position: absolute;
  top: 0.2rem;
  right: 2rem;
  border: none !important;
  background: var(--calenderBack) !important;
  backdrop-filter: blur(0.8rem);
  border-radius: 0.5rem;
  z-index: 99999999 !important;
}
.phases__calender
  > .react-calendar__navigation
  > .react-calendar__navigation__arrow {
  color: var(--pure) !important;
  font-size: 1.2rem;
}
.phases__calender
  > .react-calendar__navigation
  > .react-calendar__navigation__label {
  color: var(--pure);
  font-size: 1.1rem;
}
.react-calendar__month-view__weekdays__weekday {
  color: var(--pure) !important;
  border-bottom: none !important;
}
.react-calendar__tile {
  color: white;
}
.react-calendar__tile--now {
  background: none !important;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: var(--orange) !important;
}
.react-calendar__tile--active {
  background-color: var(--orange) !important;
  font-weight: bold !important;
}
.react-calendar__month-view__days__day--weekend {
  color: var(--pure) !important;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: var(--pure) !important;
  background: none !important;
  font-weight: bold !important;
  opacity: 50% !important;
}
.pdmb__row {
  margin-top: 2rem;
  display: flex;
  align-items: center;
}
.pdmb__row_label {
  color: var(--sidebar);
  font-size: 1.1rem;
  font-weight: 500;
}

.pdmb__input {
  border-width: 1px !important;
  background: var(--phasesInputBack) !important;
  border-color: var(--collapseSubMenu) !important;
  border: 3px solid var(--collapseSubMenu) !important;
  width: 4rem !important;
  color: var(--sidebar) !important;
  font-weight: bold !important;
  text-align: center !important;
}
.pdmb__row_slider {
  width: 35%;
}
.pdmb__row_tag {
  background-color: var(--sidebar);
  color: var(--pure);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 0.3rem;
  font-weight: 500;
}
.MuiStack-root{
  padding-top: 0 !important;
}
.pdmb__row_input__preview{
  margin-left: 4rem;
}
.pdmb__row_input__submission{
  margin-left: 2.2rem;
}
.pdmb__row_input__community{
  margin-left: 2.3rem;
}
.pdmb__row_slider{
  margin-left: 2.1rem;
}
.pdmb__row_input__review{
  margin-left: 4.2rem;
}
.pdmb__row_input__winner{
  margin-left: 4.3rem;
}
.pdmb-right_row{
  margin-right: 2rem;
}
.pdmd__Butn{
  position: absolute;
  right: 0;
  bottom: 0;
  background: var(--orange);
  border: none;
  color: var(--pure);
  border: none !important;
  font-size: 1.2rem;
  padding: 1rem;
  padding-left: 3rem;
  padding-right: 3rem;
  border-top-left-radius: 1rem;
  border-end-end-radius: 1rem;
  cursor: pointer;
}
.pdmd__close{
  position: absolute;
  right: 1rem;
  top: 1rem;
  cursor: pointer;
  font-size: 1.2rem;
  color: var(--paragraphText);
  background:none;
  border: none;
  outline: none;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  font-weight: 600;
}
.pdmd__close:hover{
  background: var(--paragraphText);
  color: var(--pure) ;
}

.MuiSlider-rail{
  color: var(--sidebar_secondory) !important;
}
.phase__timePicker .MuiSvgIcon-root{
  color: var(--orange) !important;
}
.react-calendar__navigation button:disabled {
  background-color: #ffffff2b !important;
}
.react-calendar__tile:disabled {
  background-color: #ffffff2b !important;
}