@media screen and (min-width: 944px) {
  .r_boutiDetail_wrapper {
    display: none !important;
  }
}

@media screen and (max-width: 944px) {
  .fluit_main_res {
    height: 100% !important;
    overflow: auto !important;
  }
  .r_wrapper_c2 {
    width: 90% !important;
  }
  .rr_commentCard {
    width: 100% !important;
  }
  .r_boutiDetail_wrapper {
    background-color: var(--pure);
    border-radius: 1em;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    margin-top: 1em;
    padding: 1em;
    width: 90% !important;
  }
  .brand_c1_image {
    width: 5em;
    height: 5em;
    border-radius: 1em;
  }
  .r-brandName_c1 {
    margin-left: 1em;
    font-size: 2em;
    font-weight: 400;
  }
  .sponserWraper_c1-right {
    display: flex;
    justify-content: start;
    width: 90%;
    margin: 2em auto;
  }
  .r_sponserSlider_c1 {
    background: var(--dark);
    width: 12em;
    height: 5em;
    border-radius: 1em;
    margin-right: 1em;
    margin-top: 0.5em !important;
  }
  .r_charitySlider_c1 {
    background: var(--pink);
    width: 5em;
    height: 5em;
    border-radius: 1em;
    margin-top: 0.5em !important;
  }
  .r_grid_c1 {
    margin-top: 2em;
    width: 40%;
  }
  .r-three {
    display: flex;
    justify-content: space-between;
  }
  .r_mainImg_c1 {
    width: 100%;
    height: 100%;
    border-radius: 1em;
  }
  .r_img_c1 {
    width: 32%;
    height: 6em;
    object-fit: cover;
    border-radius: 1em;
  }

  .r_main_c1 {
    padding-top: 1em;
    padding-bottom: 1em;
    width: 90% !important;
    margin: auto;
  }
  .r_grid_c1 {
    width: 90%;
    margin: auto;
  }
  .r_bountiTitleBar_c1 {
    display: flex;
    justify-content: space-between;
    padding-right: 1em;
  }
  .r_bountiTitleBar_c1 {
    display: flex;
    justify-content: space-between;
    padding-right: 1em;
  }
  .r_tags_c1 {
    display: flex;
  }
  .r_hashtag {
    color: var(--orange);
    font-weight: 500;
    margin-left: 0.5em;
  }
  .r_hashtagFirst {
    margin-left: 0 !important;
  }
  .r_badge {
    background: none !important;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    color: var(--orange) !important;
  }
  .r_icons_c1 {
    color: var(--orange) !important;
  }
  .r_bountiDescription_c1 {
    margin-top: 1em;
    display: flex;
    justify-content: space-between;
  }
  .r_desc_c1 {
    width: 30em;
  }
  .r_bText {
    font-size: 0.9em;
    text-align: justify;
  }
  .r_bountititle {
    font-size: 1.8em;
  }
  .r_boutiDetail_wrapper_inner {
    width: 90%;
    margin: auto;
  }
  .breadcrumb-res {
    width: 90% !important;
    margin: 0 !important;
  }
  .r_bounty_commet_header_c2 {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .r_comment_card_main {
    width: calc(100% - 8em);
  }
  .r_boutiDetail_wrapper_inner {
    flex-wrap: wrap !important;
  }
}
@media screen and (max-width: 733px) {
  .sponserWraper_c1-right {
    width: 90% !important;
  }
  .r_boutiDetail_wrapper_inner {
    flex-direction: column;
  }
  .r_boutiDetail_wrapper--center {
    display: flex;
    justify-content: flex-start;
    margin-top: 2em;
  }
  .r_boutiDetail_wrapper--center > div {
    width: 100% !important;
  }
  .r_boutiDetail_wrapper--left {
    display: flex;
    justify-content: flex-start;
  }
  .r-three {
    flex-direction: column !important;
    align-items: center !important;
  }
  .r_img_c1 {
    width: 100% !important;
    height: 100% !important;
    margin-top: 1em !important;
  }
  .r_mainImg_c1 {
    width: 100% !important;
    height: 100% !important;
  }
  .r-one {
    display: flex;
    justify-content: center;
  }
  .r_bounty_commet_header_c2 {
    flex-direction: column !important;
    align-items: center;
  }
  .r_bounty_commet_header_c2 > button {
    border-radius: 1em !important;
  }
  .r_bountiTitleBar_c1 {
    flex-direction: column !important;
  }
  .r_bountiMetaRight_c1 {
    margin-top: 1em !important;
  }
  .sponserWraper_c1-right {
    display: flex !important;
    justify-content: flex-start !important;
  }
  .r_bountiDescription_c1 {
    flex-direction: column !important;
  }
  .r_ust_card {
    border-radius: 1em !important;
    width: 100% !important;
  }
  .r_desc_c1 {
    width: fit-content !important;
  }
  .r_unlocks {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    margin-top: 1em;
    margin-bottom: 1em;
  }
  .btext_res {
    width: 100% !important;
  }
}

.btext_res {
  font-size: 1em !important;
  line-height: 22px !important;
  color: var(--paragraphText);
}
