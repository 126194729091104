.dashboard__top {
  width: 84%;
}
.dashboard__main {
  width: 84%;
  display: flex;
  justify-content: space-between;
}

/* tab related */
.dashboard__top__tab {
  display: flex;
  align-items: center;
}
.dash__tab {
  text-transform: capitalize;
  width: 20%;
  color: var(--sidebar_secondory);
  padding: 1rem;
  font-size: 1.2rem;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: var(--pure);
}
.dash__tab__left {
  border-top-left-radius: 1rem;
  border-end-start-radius: 1rem;
}
.dash__tab__right {
  border-top-right-radius: 1rem;
  border-end-end-radius: 1rem;
}
#dash__tab__act {
  text-transform: capitalize;
  background: var(--sidebar_secondory) !important;
  color: var(--pure);
}
.dashboard__main {
  margin-top: 2rem;
}
.dashboard__main__col {
  width: 31% !important;
  border-radius: 1rem;
}
.dashboard__main_nc {
  width: 100% !important;
}
.dashboard__top_nc {
  width: 100%;
}
.p_filter {
  background: var(--sidebar_secondory);
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.p_filter .filer__label {
  font-weight: 400;
  font-size: 1.3rem;
}
.P__search {
  background: var(--pure);
  margin-top: 2rem;
  border-radius: 1rem;
  position: relative;
}
.P__search .icons__serach {
  position: absolute;
  right: 1rem;
  top: 1rem;
}
.P__search input {
  width: 100%;
  height: 3.2rem;
  padding-left: 1rem;
  border-radius: 1rem;
  border: none;
  background: none;
}

.P__search input:focus {
  border: 2px solid var(--sidebar_secondory);
  outline: 2px solid var(--sidebar_secondory);
}
.P__pinSection {
  margin-top: 2rem;
  height: 72vh;
  background: var(--pure);
  border-radius: 1rem;
}
.pinsection__header {
  color: var(--pure);
  background: var(--sidebar_secondory);
  padding: 1rem;
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
  text-align: center;
  font-size: 1.3rem;
  font-weight: 400;
}
.pin__module_no {
  background: var(--sidebar_secondory);
  color: var(--pure);
  width: fit-content;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  border-radius: 100%;
}
.pin__module {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  margin-top: 3rem;
  /* margin-bottom: 3rem; */
}
.pin__module_label {
  margin-left: 1rem;
  font-size: 1.3rem;
  font-weight: 500;
  color: var(--sidebar_secondory);
}

.pin__childern {
  margin-top: 0rem !important;
  margin-bottom: 0rem !important;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
