/* styles.css */
body {
      background-color: #ffffff;
      margin: 0;
      padding: 0;
      text-align: center;
  }
  
  .common-styles {
      color: #262626;
      font-size: 0.875rem;
      font-family: "NimbusSanL", sans-serif;
  }
  
  .container {
      max-width: 50.5rem;
      margin: 0 auto;
      background-color: #e8eaed;
      position: relative;
      padding: 1rem;
      height: 89rem;
  }
  
  #inner-container {
      max-width: 47.5rem;
      padding: 2rem;
      height: 87rem;
      border-radius: 0.938rem;
      background-color: #ffffff;
  }
  
  #centered-image {
      display: block;
      margin: 0 auto;
      margin-top: 0.5rem;
      margin-left: 1rem;
  }
  
  .title {
      margin-top: 2rem;
      text-align: left;
  }
  
  .date, .privacy-text, .title--2, .service--text, .info--text--one, .info--text--two, .info--text--three, .info--text--four, .info--text--five, .info--text--six, .info--text--seven, .title--3, .minga--text, .title--4, .service--text--2, .service--text--one, .service--text--two, .service--text--three, .title--5, .privacy--text--2, .privacy--text--one, .privacy--text--two, .privacy--text--three, .title--6, .minga--text--2, .minga--text--one, .minga--text--two, .minga--text--three, .title--7, .change--text, .title--8, .contact--text {
      text-align: left;
      padding-left: 1rem;
  }
  
  .info--text--one, .info--text--two, .info--text--three, .info--text--four, .info--text--five, .info--text--six, .info--text--seven, .service--text--one, .service--text--two, .service--text--three, .privacy--text--one, .privacy--text--two, .privacy--text--three, .minga--text--one, .minga--text--two, .minga--text--three {
      margin-left: 4rem;
  }
  
  .title--2, .title--3, .title--4, .title--5, .title--6, .title--7, .title--8 {
      margin-top: 2rem;
  }
  
  .line-height {
      line-height: 1.5;
  }
  