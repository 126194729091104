.upload__sponser__wrapper {
  position: relative;
}
.upload__sponser__select {
  background: var(--pure);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  position: absolute;
  width: 90%;
  z-index: 99;
  border-radius: 0.5rem;
  padding: 1rem;
}
.sel__sponser {
  display: flex;
  align-items: center;
}
.uploader_sponser__name{
    margin-left: 1rem;
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--paragraphText);
}
.upload__sponser_checkbox>.ant-checkbox-checked>.ant-checkbox-inner{
    background-color: var(--orange) !important;
    border: 1px solid var(--orange) !important;
}
.upload-alert{
  position: fixed;
  top: 10px;
  right: 4rem;
  width: 20%;
  text-align: center;
  z-index: 1000;
}