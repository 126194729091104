.checklist__todo {
  display: flex;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border: 1px solid var(--sidebar_secondory);
}
.checklist__label {
  font-size: 1rem;
  color: var(--pure);
  font-weight: 700;
}
.checklist__status {
  color: var(--pure);
  opacity: 0.573;
  font-size: 0.8rem;
}
.ckecklist_checkbox {
  margin-left: 1rem !important;
}
.ckecklist_checkbox > .ant-checkbox {
  display: inline-block !important;
  width: 1.3rem !important;
  height: 1.3rem !important;
}
.ckecklist_checkbox > .ant-checkbox > .ant-checkbox-inner {
  display: inline-block !important;
  width: 1.3rem !important;
  height: 1.3rem !important;
}
.ckecklist_checkbox > .ant-checkbox-checked > .ant-checkbox-inner {
  background-color: var(--greenType) !important;
  border: 1px solid var(--greenType) !important;
  color: var(--pure) !important;
}

.checklistHeader {
  background-color: var(--sidebar_secondory);
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  padding: 1rem;
  color: var(--pure);
  font-size: 1.5rem;
}
.checkList {
  position: relative;
  width: 100%;
  height: 100%;
}
.checklistFooter {
  background-color: var(--sidebar_secondory);
  border-end-start-radius: 1rem;
  border-end-end-radius: 1rem;
  padding-top: 0;
  color: var(--pure);
  font-size: 1.5rem;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  font-weight: 700;
  font-size: 1.3rem;
  text-align: center;
  flex-direction: column;
}
.progressDetail__checklist {
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin: auto;
  width: fit-content !important;
}
.chklist__progress {
  border-end-end-radius: 0.5rem;
}
:where(.css-dev-only-do-not-override-gzal6t).ant-checkbox .ant-checkbox-inner {
  background-color: var(--orange) !important;
  border: var(--orange) !important;
}
:where(.css-dev-only-do-not-override-gzal6t).ant-checkbox-checked
  .ant-checkbox-inner {
  background-color: var(--orange) !important;
  border: var(--orange) !important;
}
.ant-checkbox::before {
  content: "!" !important;
  color: var(--pure) !important;
  font-weight: bold !important;
  position: absolute !important;
  top: 0.2rem !important;
  left: 0.5rem !important;
}
.ant-checkbox-checked::before {
  content: "" !important;
  color: var(--pure) !important;
}
.upload__sponser_checkbox>.ant-checkbox>.ant-checkbox-inner {
  background: var(--pure) !important;
  border: 1px solid var(--orange) !important;
}
.upload__sponser_checkbox>.ant-checkbox-checked>.ant-checkbox-inner {
  background: var(--orange) !important;
  border: var(--orange) !important;
}
