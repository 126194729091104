.submission__step1 {
  width: 47rem;
  height: 32rem;
  background: var(--pure);
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  position: relative;
}
.submission__no {
  width: 47rem;
  height: 8rem;
  background: var(--pure);
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  position: relative;
  display: flex; /* Enable flexbox layout */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}
.submission__step__inner {
  width: 90%;
  margin: 2rem auto;
}
.submit__button_Submisn {
  background: var(--orange);
  border: none;
  outline: none;
  color: var(--pure);
  scale: 1;
}
.submit__button_Submisn:hover {
  color: var(--pure) !important;
  scale: 1.1;
}
#s1sDesc:focus,
#sTilte:focus {
  border: 1px solid var(--orange);
}
#s1sDesc:hover,
#sTilte:hover {
  border: 1px solid var(--orange);
}
.STILTELAVBEL {
  font-weight: bold;
}
.main__submissionAsset__Frame {
  width: 100%;
  height: 18rem;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
  display: flex;
  border-radius: 1rem;
  justify-content: center;
}
.uploadIcon__submission {
  width: 3rem;
}
.isReviewed__Terms {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.submi__termsACon {
  height: 15rem;
  overflow: auto;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  padding: 0.5rem;
  border-radius: 0.5rem;
}
.step1_sub_buttonR {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  width: 97%;
  bottom: 1rem;
  left: 0;
}
/* .step1__SubmissionButooRow {
  justify-content: space-between;
} */
.submissionSkipNow {
  cursor: pointer;
  color: var(--orange);
}
