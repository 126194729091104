@font-face {
  font-family: "NimbusSanL";
  src: url("./fonts/NimbusSanL-Bol.otf") format("otf")
      url("./fonts/NimbusSanL-BolIta.otf") format("otf"),
    url("./fonts/NimbusSanL-Reg.otf") format("otf"),
    url("./fonts/NimbusSanL-RegIta.otf") format("otf");
}

/* assets for global style */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scrollbar-width: thin !important;
}
::-webkit-scrollbar {
  width: 0px;
}
body {
  font-family: "NimbusSanL", sans-serif; /* Use the font in your styles */
}

/* default theme */
.default-theme {
  --loginHeroHeading: #066791;
  --background: #ececec;
  --sidebar: #046791;
  --sidebar_fade: #8ec9e2;
  --sidebar_secondory: #0b5281;
  --sidebar_secondory-border: rgba(0, 0, 0, 0.164);
  --pure: #ffffff;
  --orange: #e98326;
  --dark: #313131;
  --dark_2: #272727;
  --pink: rgb(235, 139, 155);
  --veryDarkblue: #1a3053;
  --paragraphText: #272727;
  --headingBlue: #021438;
  --chat_dateSelector: #c8ebfa;
  --chatStatus: #fafafa;
  --receiveChat: #3775b1;
  --collapseSubMenu: #00152926;
  --greenType: #199377;
  --dialogOverlay: #0467914f;
  --calenderBack: #02143899;
  --phasesInputBack: #91919126;
  --inputFocus: #044460e5;
  --grayShade1: #717171;
  --grayShade2: #494949;
  --golden: #eeb52d;
  --footer: #efefef;
  --light: #f2f2f2;
  --blurDiv: #04679185;
}

/* body */
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}
.color-headingBlue {
  color: var(--headingBlue);
}
#hamburger {
  display: none !important;
}
.swiper-slide {
  width: 100% !important;
  height: 100% !important;
}
.carousel_reward_slide {
  width: 6em !important;
}
.fluit_main_res {
  overflow: scroll !important;
}
.chatroom_float_button {
  color: var(--orange);
  font-size: 3em !important;
  position: absolute;
  z-index: 999999999;
  bottom: 1em;
  right: 0.3em;
  background: var(--pure);
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 0.3em;
}
.noBtn {
  background: none !important;
  border: none !important;
  outline: none !important;
}

/* flex styling */
.flex {
  display: flex;
}
.justify-between {
  justify-content: space-between;
}
.items-center {
  align-items: center;
}

.items-end {
  align-items: flex-end;
}
.justify-end {
  justify-content: end;
}
.justify-center {
  justify-content: center;
}
.flex-center {
  display: flex;
  justify-content: center;
}
.flex-center-c {
  display: flex;
  align-items: center;
}

a {
  text-decoration: none;
}
.highlight-orange {
  color: var(--orange);
}
.flex-col {
  flex-direction: column;
}

.w-full {
  width: 100%;
}

.m-half-right {
  margin-right: 0.5em;
}

/* responsiveness */

/* bounti preview page */
@media screen and (max-width: 1800px) {
  .fluid-responsive {
    width: 100%; /* Fluid width based on percentage */
    max-width: 100% !important;
    min-width: 100% !important;
  }
  /* .main_wrapper_c1_responsive,
  .div_67_respon {
    max-width: 75em !important;
  } */
  .right_c1_colapse {
    width: 50em !important;
  }
}

@media screen and (max-width: 1736px) {
  /* .main_wrapper_c1_responsive,
  .div_67_respon {
    max-width: 71em !important;
  } */
  .right_c1_colapse {
    width: 45em !important;
  }
}

@media screen and (max-width: 1770px) {
  .fluid-responsive {
    width: 100%;
    max-width: 100% !important;
    min-width: 100% !important;
  }
  /* .main_wrapper_c1_responsive,
  .div_67_respon {
    width: 68em !important;
  } */
  .right_c1_res {
    width: 41em !important;
  }
  .right_c1_colapse {
    width: 50em !important;
  }
  .div_67_respon_collapse {
    width: 75em !important;
  }
}

@media screen and (max-width: 1748px) {
  .fluid-responsive {
    width: 100%;
    max-width: 100% !important;
    min-width: 100% !important;
  }
  /* .main_wrapper_c1_responsive,
  .div_67_respon {
    width: 65em !important;
  } */
  .div_67_respon_collapse {
    width: 75em !important;
  }
}

@media screen and (max-width: 1692px) {
  /* .main_wrapper_c1_responsive,
  .div_67_respon {
    width: 61em !important;
  } */
  .left_c1_res {
    width: 16em !important;
  }
  .brandName_c1_res {
    font-size: 1.5em !important;
  }
  .bountititle_res {
    /* font-size: 1.5em !important; */
  }
  .hashtag_res {
    /* font-size: 0.8em !important; */
  }
  .numberOfEngage_C1_res {
    /* font-size: 0.8em !important; */
  }
  .responsive_label {
    /* font-size: 0.8em; */
    font-weight: 500;
  }
  .btext_res {
    /* font-size: 0.8em !important; */
  }
  .rewardName_res {
    /* font-size: 0.8em !important; */
  }
  .mainImg_c1_res {
    width: 100% !important;
  }
  .img_c1_res {
    width: 30% !important;
  }
  /* .main_wrapper_c1_responsive,
  .div_67_respon {
    max-width: 70em !important;
  } */
}

/* also font changes from this breakpoint */
@media screen and (max-width: 1692px) {
  .right_c1_res {
    width: 38em !important;
  }
  .btext_res {
    width: 26em !important;
  }
  /* .main_wrapper_c1_responsive,
  .div_67_respon {
    width: 60em !important;
  } */
  .div_30_responsive {
    width: 20em !important;
  }
}
@media screen and (min-width: 1366px) {
  .chatroom_float_button {
    display: none !important;
  }
  .desktop_collapse_then {
    width: calc(100% - 86px) !important;
  }
  .when_colapse_main_awrapper {
    width: 80em !important;
  }
  .desktop_noColl_then {
    margin-left: 0rem !important;
  }
  .mobile-sidebar {
    display: none !important;
  }
}
/* for mobile starting */
@media screen and (max-width: 1365px) {
  .desktop-sidebar {
    display: none !important;
  }
  .collapseSidebar {
    display: none !important;
  }
  #hamburger {
    display: block !important;
    margin-right: 1em;
  }
  .main_sidebar_res {
    /* display: none !important; */
    position: absolute !important;
    z-index: 999999 !important;
  }
  .hide_main_sidebar_res {
    display: none !important;
  }
  .fluit_main_res {
    width: 100% !important;
  }
  .div_30_responsive {
    display: none !important;
  }
  .div_30_responsive-withFloat {
    position: absolute;
    left: 0em;
    top: 1em;
    width: 90% !important;
    z-index: 9999;
    height: 100% !important;
  }

  .bounti-preview-res {
    width: 100%;
  }
  .bounti-preview-res {
    justify-content: center !important;
  }
  .breadcrumb-res {
    width: 57em !important;
    margin: auto;
  }
  .div_67_respon {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media screen and (max-width: 944px) {
  .main_wrapper_c1_responsive {
    display: none !important;
  }
  .breadcrumb-res {
    width: 90% !important;
    margin: auto !important;
  }
}

@media screen and (max-width: 733px) {
  .rr_commentCard {
    display: flex;
    flex-direction: column;
    height: auto !important;
  }
  .rr_comment_card_main {
    flex-direction: column !important;
    width: 100% !important;
  }
  .userDeatailParent {
    width: 100% !important;
  }

  .rr_commentCard > img {
    width: 100% !important;
    border-radius: 1em !important;
  }
  
  .comment_submission_parent {
    width: 70% !important;
    margin: auto;
    height: 24rem !important;
    overflow-y: scroll !important;
    border: 1px solid red;
  }
  .r_comment_card_main {
    width: 100% !important;
  }
  .likeandmore_submission {
    margin-top: 1em !important;
  }
}

@media screen and (min-width: 1800px) {
  .right_c1_colapse {
    width: 54.5em !important;
  }
}
@media screen and (max-width: 1736px) {
  .right_c1_colapse {
    width: 45em !important;
  }
}
@media screen and (max-width: 1692px) {
  .when_colapse_main_awrapper {
    width: 67em !important;
  }
  .rr_commentCard_collapse {
    width: 67em !important;
  }
}

@media screen and (max-width: 1600px) {
  .when_colapse_main_awrapper {
    width: 66em !important;
  }
  .rr_commentCard_collapse {
    width: 66em !important;
  }
}

@media screen and (max-width: 1516px) {
  .when_colapse_main_awrapper {
    width: 60em !important;
  }
  .rr_commentCard_collapse {
    width: 60em !important;
  }
}

.reqwrdWarppers {
  margin-top: 2em !important;
}

.mt-half {
  margin-top: 0.5em;
}

.cursor-pointor {
  cursor: pointer;
}

.r_avtar_grp_c1 {
  margin-top: 1rem !important;
}

.r_bountititle {
  font-weight: 100;
}

.sendicon {
  margin-top: 0.5em;
  margin-right: 0.5em;
  cursor: pointer;
}

#hiddenCanvas {
  display: none;
}

/* ant-d styles */
.ant-menu-submenu {
  margin-top: 1em !important;
}
.ant-menu-item {
  padding-left: 0.4em !important;
  padding-right: 0.4em !important;
  margin-top: 1rem !important;
}
.ant-menu-item-only-child {
  padding-left: 2rem !important;
}
.ant-menu-item-only-child > .ant-menu-title-content {
  font-size: 1.2em !important;
  font-family: "NimbusSanL", sans-serif !important;
}
.ant-menu-submenu-title {
  padding-left: 0.4em !important;
  padding-right: 0.4em !important;
}
.ant-menu-title-content {
  font-size: 1.1rem !important;
  color: var(--pure);
  font-family: "NimbusSanL", sans-serif !important;
}
i.ant-menu-submenu-arrow {
  color: var(--pure) !important;
}
.ant-menu-submenu > .ant-menu-title-content {
  font-size: 0.8rem !important;
}
:where(.css-dev-only-do-not-override-p7e5j5).ant-menu-light
  .ant-menu-item-selected,
:where(.css-dev-only-do-not-override-p7e5j5).ant-menu-light
  > .ant-menu
  .ant-menu-item-selected {
  background-color: var(--sidebar_secondory) !important;
  color: var(--pure) !important;
}
:where(.css-dev-only-do-not-override-p7e5j5).ant-menu-light:not(
    .ant-menu-horizontal
  )
  .ant-menu-submenu-title:hover,
:where(.css-dev-only-do-not-override-p7e5j5).ant-menu-light
  > .ant-menu:not(.ant-menu-horizontal)
  .ant-menu-submenu-title:hover {
  background-color: var(--sidebar_secondory) !important;
}
:where(.css-dev-only-do-not-override-p7e5j5).ant-menu-light:not(
    .ant-menu-horizontal
  )
  .ant-menu-item:not(.ant-menu-item-selected):hover,
:where(.css-dev-only-do-not-override-p7e5j5).ant-menu-light
  > .ant-menu:not(.ant-menu-horizontal)
  .ant-menu-item:not(.ant-menu-item-selected):hover {
  background-color: var(--sidebar_secondory) !important;
}
:where(.css-dev-only-do-not-override-p7e5j5).ant-menu-light.ant-menu-inline
  .ant-menu-sub.ant-menu-inline,
:where(.css-dev-only-do-not-override-p7e5j5).ant-menu-light
  > .ant-menu.ant-menu-inline
  .ant-menu-sub.ant-menu-inline {
  background: var(--collapseSubMenu) !important;
}
/* ant-d styles */

/* media quaris */
@media screen and (min-width: 1516px) and (max-width: 1600px) {
  .when_colapse_main_awrapper {
    width: 62em !important;
  }
  .right_c1_colapse {
    width: 41em !important;
  }
  .rr_commentCard_collapse {
    width: 62em !important;
  }
}
.rr_comment_card_main_collapse {
  width: 100% !important;
}

.global-select {
  border: 1px solid var(--orange) !important;
  outline: none !important;
  border-radius: 0.3rem;
  height: 1.5rem;
  background: none !important;
  width: 7.25rem;
  color: var(--orange);
  font-weight: 400;
  font-size: 0.875rem;
}

.highlightOption,
.option:hover {
  background: var(--orange) !important;
}

.chip {
  background: var(--orange) !important;
}

.search-wrapper {
  border: 1px solid var(--orange) !important;
  border-radius: 0.3rem !important;
}

.notFound {
  color: var(--paragraphText);
}

.overHeight {
  height: 49rem !important;
}

.leftMAinArea {
  height: 87% !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between;
}

.ml-0 {
  margin-left: 0 !important;
}
.multiSelectContainer {
  width: 95% !important;
}

.global-select {
  height: 2rem;
}

.ant-dINPUT {
  border: 1px solid var(--orange);
  width: 70%;
}
.ant-dINPUT:focus {
  border: 1px solid var(--orange);
}
.ant-dINPUT:hover {
  border: 1px solid var(--orange);
}
.hashtagsMulti {
  width: 70% !important;
}
.ant-modal-footer {
  display: none !important;
}
.ant-modal-content {
  padding: 0px !important;
  border-radius: 1rem !important;
}
.ant-modal-body {
  border-radius: 1rem !important;
}
.media-wrapper {
  width: fit-content !important;
}
.ustInput {
  width: 100%;
  background: none !important;
  border: none;
  outline: none;
  text-align: center;
  color: var(--pure);
  font-size: 1.2rem;
}
.ustInput::placeholder {
  text-align: center;
  color: var(--pure);
  opacity: 60%;
}
.close_dialog_wrapper {
  display: flex;
  justify-content: end;
  position: absolute;
}
/* .close_dialog {
  padding: 1rem;
  background-color: var(--pure);
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 1rem;
  font-weight: 600;
} */
.close_dialog {
  background-color: var(--pure);
  padding: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
  /* border-radius: 0.5rem; */
  cursor: pointer;
  position: absolute;
  top: -27rem;
  right: 1rem;
  z-index: 100000;
  border: none;
  outline: none;
  position: relative;
} 
/* test border */
.border {
  border: 2px solid red;
}
:where(.css-dev-only-do-not-override-gzal6t).ant-checkbox .ant-checkbox-inner {
  background-color: var(--orange) !important;
  border: var(--orange) !important;
}
:where(.css-dev-only-do-not-override-gzal6t).ant-checkbox-checked
  .ant-checkbox-inner {
  background-color: var(--orange) !important;
  border: var(--orange) !important;
}
.ant-checkbox::before {
  content: "!" !important;
  color: var(--pure) !important;
  font-weight: bold !important;
  position: absolute !important;
  top: 0.2rem !important;
  left: 0.5rem !important;
}
.ant-checkbox-checked::before {
  content: "" !important;
  color: var(--pure) !important;
}
.upload__sponser_checkbox > .ant-checkbox > .ant-checkbox-inner {
  background: var(--pure) !important;
  border: 1px solid var(--orange) !important;
}
.upload__sponser_checkbox > .ant-checkbox-checked > .ant-checkbox-inner {
  background: var(--orange) !important;
  border: var(--orange) !important;
}
.bounticreation__67__collapse {
  width: 74% !important;
}
.bounticreation__67__nocollapse {
  width: 74% !important;
}
#checklistWarraper_Coll {
  width: 21em !important;
}
.ant-checkbox-inner {
  background-color: var(--orange) !important;
  border: var(--orange) !important;
}
.media-wrapper {
  width: 100% !important;
  height: 100%;
  border-radius: 1rem !important;
  display: flex !important;
  justify-content: center !important;
}
.media-wrapper > img {
  border-radius: 1rem !important;
}
.media-wrapper > video {
  border-radius: 1rem !important;
}
.media-wrapper > audio {
  border-radius: 1rem !important;
}
.reward_setter_carousel {
  width: 35rem;
  margin: 0 !important;
}
.relative {
  position: relative;
}
.detailcard_c1_wrapper,
.detailcard_c1_wrapper_sponser {
  display: none;
}
.bountypreviewCharity_c1 {
  transition: all 0.5s;
  cursor: pointer;
}
.bountypreviewSponser_c1 {
  transition: all 0.5s;
  cursor: pointer;
}
.bountypreviewCharity_c1:hover .detailcard_c1_wrapper {
  display: block;
}
.bountypreviewSponser_c1:hover .detailcard_c1_wrapper_sponser {
  display: block;
}
/* .carousel_reward_view_carousel:hover .reward__hover__card{
  display: block;
} */
.bountypreview__InteractionCard {
  display: none;
  cursor: pointer;
}
.bountipreview__likesAndList:hover .bountypreview__InteractionCard {
  display: block;
}
.upload__sponser__sliderBody {
  margin-right: 1rem;
  width: 12rem;
  height: 5rem;
  border-radius: 1rem;
}
.charitySlider_c1 {
  width: 5rem;
  height: 5rem;
  border-radius: 1rem;
}
/* #scrollableDiv {
  scrollbar-color: var(--orange) var(--pure);
  scrollbar-width: 3rem !important;
}
#scrollableDiv::-webkit-scrollbar {
  width: 0.1rem;
}
#scrollableDiv::-webkit-scrollbar-thumb {
  background: var(--orange) !important;
  border-radius: 10px;
  width: 1rem;
} */

/* media preview dialog goes here */
.media--wrapper {
  width: 70rem;
  display: flex;
  flex-direction: row;
  position: relative;
  background: #ffffff;
  padding: 2rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  height: 44.5rem !important;
  border: 0.063rem solid #b8b8b8;
  border-radius: 1rem !important;
  justify-content: space-between;
}
.media-view-slide {
  border-radius: 0.5rem;
}

.media--left {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.media--wrapper1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 1rem;
  margin-top: 2rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  height: 28.438rem !important;
}

.media--wrapper-container {
  width: 100%;
  display: flex;
}

.media-view-slide {
  /* width: 23% !important; */
  width: 7rem !important;
  height: 7rem !important;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  cursor: pointer;
}
.media__view__swiper {
  margin: 0 !important;
  width: 95% !important;
  padding: 1rem !important;
  padding-bottom: 0.4rem !important;
  margin-top: 1.5rem !important;
}

.scrollbar--left img {
  margin-top: 6rem;
  cursor: pointer;
}
.scrollbar--right img {
  margin-top: 6rem;
  cursor: pointer;
}
.media--right {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.media--right-text {
  height: 30%;
  margin-left: 1rem;
  display: flex;
  align-items: center;
}

.image-name {
  font-size: 1.25rem;
  color: var(--headingBlue);
  font-weight: 400;
  margin-top: -4rem;
}

.text-container {
  display: flex;
  flex-direction: column;
}

.bounty-container {
  display: flex;
  align-items: center;
}

.image-name {
  font-size: 1.25rem;
  color: #021438;
  font-weight: 400;
  margin-top: 1rem;
}

.bounty-name {
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #e98326;
  font-weight: 400;
  margin-top: 0.5rem;
  margin-bottom: 0;
}

/* .image_logo_one img {
  margin-left: 9.5rem;
} */
.image_logo_two {
  margin-left: 0.5rem;
}
.image_logo_three {
  margin-left: 0.5rem;
}
.image_logo_four {
  margin-left: 0.5rem;
}

.description {
  font-size: 1rem;
  line-height: 1.375rem;
  color: var(--paragraphText);
  font-weight: 400;
  margin-top: 1rem;
}

.media--right-box {
  height: 27.438rem;
  border: 1px solid #0a5280;
  margin-left: 1rem;
  border-radius: 0.938rem;
  background-color: #f5f5f5;
  width: 27rem;
  position: relative;
  z-index: 0;
  overflow: hidden;
}

.scrollbar {
  overflow-y: auto;
  height: calc(100% - 3.938rem);
  overflow-x: hidden;
  margin-right: 0.3rem;
}
.scrollbar::-webkit-scrollbar {
  width: 0.188rem;
}

.scrollbar::-webkit-scrollbar-thumb {
  height: 2.313rem;
  border-radius: 0.125rem;
  background-color: #e98326;
}

.media--right--innerbox--one,
.media--right--innerbox--two,
.media--right--innerbox--three {
  width: 25rem;
  height: 5.563rem;
  border-radius: 0.938rem;
  background-color: #3775b1;
  margin-left: 1rem;
  margin-top: 1rem;
}

.media--right--commentbox {
  width: 27rem;
  height: 3.938rem;
  border-radius: -0.75rem;
  background-color: var(--sidebar_secondory);
  margin-top: 2rem;
  flex-direction: row;
  position: sticky;
}

.media--right--plusbutton {
  font-size: 2.25rem;
  line-height: 3.438rem;
  color: #fafafa;
  font-weight: 300;
  font-family: "Roboto";
  margin-left: 0.5rem;
  cursor: pointer;
  border-radius: 0.5rem;
  margin-top: -1.5rem;
}

.media--right--textbox {
  width: 23.5rem;
  height: 2.25rem;
  border-radius: 0.313rem;
  background-color: #ffffff;
  margin-top: -3rem;
  margin-left: 2.5rem;
  display: flex;
  position: sticky;
  top: 0;
  z-index: 1;
}

.media--right--textbox input {
  flex: 1;
  border: none;
  outline: none;
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 0.313rem;
}

.textbox_image_one img {
  margin-left: -2rem;
  margin-top: 0.5rem;
}
.textbox_image_two img {
  margin-right: 0.5rem;
  margin-top: 0.5rem;
}

.innerbox--image--one img {
  margin-top: 0.5rem;
  margin-left: 1rem;
}

.innerbox--name p {
  line-height: 18px;
  color: #fafafa;
  font-weight: 700;
  margin-left: 4.5rem;
  margin-top: -1.5rem;
  font-size: 0.875rem;
}

.innerbox--description p {
  line-height: 18px;
  color: #fafafa;
  font-weight: 400;
  font-size: 0.75rem;
  margin-left: 4.5rem;
}
/* media preview dialog end here */

.global__dialog__minga {
  backdrop-filter: blur(0.6rem);
}
.tips > span {
  display: none !important;
}
.mediaV__replise {
  z-index: -999 !important;
  padding-top: 2rem;
  width: 25rem !important;
  margin-left: 1rem;
}
.overight__fluid {
  padding: 0 !important;
}
.guest__header__button {
  width: 11.75rem !important;
  padding: 0.8rem !important;
}
.guest__header__button__left {
  border-top-left-radius: 0.5rem !important;
  border-end-start-radius: 0.5rem !important;
}
.guest__header__button__right {
  border-top-right-radius: 0.5rem !important;
  border-end-end-radius: 0.5rem !important;
}
#guest__header__Act {
  text-transform: capitalize;
  background: var(--sidebar_secondory) !important;
  color: var(--pure);
  box-shadow: var(--sidebar_secondory) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
.guestArea__inner__heading {
  color: var(--sidebar);
  font-size: 3.313rem;
  font-weight: 400;
  text-align: center;
}
.guestArea__inner__paragraph {
  font-size: 1.813rem;
  color: var(--grayShade2);
  text-align: center;
}
.guestArea__inner {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.guestArea__Grid {
  display: flex;
  justify-content: center;
  margin-top: 8rem;
}
.guestArea__GridBox {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: 12.5rem;
  height: 12.5rem;
  background: var(--pure);
  border-radius: 1rem;
  cursor: pointer;
}
.guest__notes {
  font-size: 1rem;
  color: var(--paragraphText);
  font-weight: 400;
  text-align: center;
  margin-top: 2rem;
}

.guest__afterSelect__message {
  font-size: 1.8rem;
  color: var(--paragraphText);
  font-weight: 400;
  text-align: center;
  margin-top: 5rem;
  width: 15rem;
}

.guest__bgBand {
  background-color: var(--golden);
  height: 10rem;
  width: 100%;
  position: absolute;
  top: 28rem;
  transform: rotate(146deg);
  left: 0;
}
.fluid_parent {
  position: relative;
  background: url("../public/tests/bgband.png");
  background-position: top;
  background-repeat: no-repeat;
}
.fluid_parent2 {
  position: relative;
  background: url("../public/tests/bgband2.png");
  background-position: top;
  background-repeat: no-repeat;
}
.band_image_1 {
  position: absolute;
  bottom: 0;
}
.band_image_2 {
  position: absolute;
  top: 5rem;
  right: 5rem;
}
.guestArea__inner__one {
  width: 25rem;
  height: 13.5rem;
  background-color: var(--pure);
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.guestArea__inner__two {
  width: 25rem;
  height: 24.313rem;
  background-color: var(--pure);
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.next-inner-main {
  margin-top: 3rem;
}
.guestArea__inner__noteMesg {
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 1rem;
  position: absolute;
  background-color: var(--pure);
  width: 24rem;
  right: 13rem;
  color: var(--paragraphText);
  line-height: 1.5rem;
}
.position__setting {
  position: absolute;
  right: 2rem;
  bottom: 2rem;
}
textarea {
  font-family: "NimbusSanL", sans-serif;
}
.p_filter_ddlist {
  color: var(--sidebar_secondory);
  border-radius: 1rem;
  padding: 1rem;
}

.ant-menu-light .ant-menu-item-selected,
.ant-menu-light > .ant-menu .ant-menu-item-selected {
  background-color: var(--sidebar_secondory) !important;
  width: 14rem !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
}
.aanoucementTabBtn {
  background-color: var(--sidebar_secondory);
  color: var(--pure);
  width: 11.75rem;
  height: 3.313rem;
  border-radius: 8px;
  filter: drop-shadow(0px 3px 3.5px rgba(0, 0, 0, 0.16));
  border: none;
  outline: none;
  font-size: 1.2rem;
}
.announcementTab__creation {
  margin-top: 1rem;
}
.annoucPlusBtn {
  background: var(--orange);
  color: var(--pure);
  margin-left: 2rem;
  padding-left: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 1rem;
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 1rem;
  cursor: pointer;
  border-radius: 0.4rem;
}
.homeMasonry__components {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  padding-left: 1rem;
  border-radius: 1rem;
}
.login__stats {
  font-weight: bold;
}
.dash__tab__act {
  box-shadow: var(--sidebar_secondory) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.hide {
  display: none !important;
}
.flex-wrap {
  flex-wrap: wrap;
}
.profile__submission__card_relative {
  position: relative;
}
.blur-full-div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--blurDiv);
  backdrop-filter: blur(5px);
  border-radius: 0.5rem;
}

.profile-blur-sub {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--pure);
  font-size: 0.8rem;
  font-weight: bolder;
}

.global__alert_antd {
  position: absolute;
  bottom: 1rem;
  right: 17rem;
  display: flex !important;
}
.global__alert_antd__hide {
  display: none !important;
}
.P__pinSection {
  height: 53rem !important;
  overflow: auto !important;
}
/* bounty notification */
.email_NotifiConatiner {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}
.bounty__notification__drop {
  position: absolute;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 15rem;
  padding: 1rem;
  border-radius: 1rem;
  background-color: var(--pure);
  z-index: 9;
}
.email_NotifiConatiner__Left {
  font-weight: bolder;
  color: var(--headingBlue);
}
.notificati__close {
  display: flex;
  justify-content: end;
}
.notificati__close > button {
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  padding: 0.5rem;
  border-radius: 0.1rem;
  font-weight: bold;
  border: none;
  outline: none;
}
.logoutButoon {
  background: var(--orange);
  color: var(--pure);
  border: none;
  outline: none;
  font-weight: bold;
  font-size: 0.8rem !important;
  cursor: pointer;
}
.ant-menu .ant-menu-submenu-arrow{
  margin-right: 0.7rem;
}
@media screen and (min-width: 1100px) and (max-width: 1366px) {
  .guest__header__button {
    width: 8.75rem !important;
    padding: 0.8rem !important;
  }
}