.ad-form {
      width: 300px;
      margin: 0 auto;
      padding: 20px;
      border: 1px solid #ccc;
      border-radius: 5px;
      margin-top: 4rem;
    }
    
    .ad-form label {
      display: block;
      margin-bottom: 10px;
      font-size: 0.875rem;
      font-family: "NimbusSanL", sans-serif;
      margin-top: 1rem;
      text-align: left;
    }
    
    .ad-form input[type="text"],
    .ad-form input[type="file"],
    .ad-form select {
      width: 100%;
      padding: 8px;
      margin-bottom: 10px;
     
    }
    
    .ad-form button {
      background-color: #046791;
      color: white;
      padding: 10px 20px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
    }
    
    .ad-form button:hover {
      background-color: #046791;
    }