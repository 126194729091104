.table-container {
    text-align: center;
  }
  
  .table {
    width: 100%;
    border-spacing: 0;
  }
  
  .table th,
  .table td {
    border: 0.063rem solid #dddddd;
    text-align: left;
    padding: 0.5rem;
  }
  
  .table th {
    background-color: burlywood;
    font-weight: bold;
  }
  
  tr:nth-child(even) {
    background-color: #dddddd;
  }
  .table-container {
    text-align: center;
    margin: 1.25rem;
  }
  
  .table {
    border-collapse: collapse;
    width: 100%;
  }
  
  .table th,
  .table td {
    border: 0.063rem solid #dddddd;
    text-align: left;
    padding: 0.75rem;
  }
  
  .table th {
    background-color: burlywood;
    color: white;
    font-weight: bold;
  }
  
  .table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .table tbody tr:hover {
    background-color: #e0e0e0;
  }
  