.Reward--wrapper {
  width: 60%;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 1rem;
  background: var(--pure) !important;
  padding: 2rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  height: 58rem !important;
  margin-top: 1rem;
}

/* First row data CSS */
.Reward--first--row--data {
  height: 10%;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.Reward--first--row--data--left {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
}

.Reward--first--row--data--right {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  /* Adjusted height */
}

.Reward--first--row--data--left .first-sentence {
  text-align: center;
  color: var(--sidebar);
  font-weight: 600;
  font-size: 1rem;
}

.Reward--first--row--data--left .second-sentence {
  color: var(--dark);
  font-size: 0.9rem;
  margin-top: 0.3rem;
  line-height: 20px;
  font-weight: 500;
  text-align: center;
}

.Reward--first--row--data--right .first-sentence {
  text-align: center;
  color: var(--sidebar);
  font-weight: 600;
  font-size: 1rem;
}

.Reward--first--row--data--right .second-sentence {
  color: var(--dark);
  font-size: 0.9rem;
  margin-top: 0.3rem;
  line-height: 20px;
  font-weight: 500;
  text-align: center;
}

/* Second row data CSS */
.Reward--second--row--data {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.Reward--second--row--data--left {
  width: 40%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  border-radius: 1rem;
  background: var(--background) !important;
  padding: 1rem;
  /* Adjusted height */
}

.Reward--second--row--data--left form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.Reward--second--row--data--left .form-field {
  display: flex;
  flex-direction: column;
}

.form-field input:focus,
.form-field textarea:focus,
.form-field select:focus {
  border: 2px solid var(--orange);
  outline: none;
}

.category__sub__type,
.delivery__method {
  flex: 1;
  margin-right: 1rem;
  /* Adjust as needed for spacing between the two divs */
}

.form-filed2 {
  display: flex;
}
.dropdown__2 {
  width: 100%;
  /* padding: 0.5rem; */
  border: 1px solid #ececec;
  border-radius: 0.5rem;
  color: var(--orange);
  height: 2rem;
}
/* Add this to your existing CSS */
.row-container {
  display: flex;
  justify-content: space-between;
}

.dropdown {
  width: 60%;
  /* padding: 0.5rem; */
  border: 1px solid #ececec;
  border-radius: 0.5rem;
  color: var(--orange);
  height: 2rem;
}

.radio-group {
  width: 50%;
  margin-left: 2rem;
}

.Reward--second--row--data--left label {
  color: var(--sidebar);
  margin-bottom: 0.5rem;
  font-size: 1rem;
}

/* .Reward--second--row--data--left select, */
.Reward--second--row--data--left input,
.Reward--second--row--data--left textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ececec;
  border-radius: 0.5rem;
}

.Reward--second--row--data--right {
  width: 60%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  border-radius: 1rem;
  background: var(--pure) !important;
  margin-left: 2rem;
}

.Reward__Image__section {
  display: flex;
  justify-content: space-between;
}

.Reward__Image__section2 {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
}

.Reward__Image__Upload {
  width: 25%;
  height: 7rem;
  /* Adjust the height as needed */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  border-radius: 1rem;
  background: var(--background) !important;
  padding: 2rem;
  margin-right: 1rem;
  /* Adjust margin as needed */
}

.Reward__DROpper_image {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}

.Reward__DROpper_image > img {
  width: 30%;
}

.Reward__Preview__Container {
  height: 10%;
  text-align: center;
  margin-top: 1rem;
}

.Reward__Preview__Container .second-sentence {
  color: var(--dark);
  opacity: 60%;
  font-size: 0.8rem;
  margin-top: 0.3rem;
}

.Reward__Preview__Container .first-sentence {
  text-align: center;
  color: var(--sidebar);
  font-weight: 600;
  font-size: 1rem;
}

.Reward__game__pass {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  padding-top: 0.2rem;
}

.Reward__game__pass__text {
  height: 100%;
  text-align: left;
}

.Reward__game__pass .first-sentence {
  width: 50%;
  color: var(--sidebar_secondory);
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.3rem;
}

.Reward__game__pass .second-sentence {
  width: 50%;
  color: var(--sidebar);
  font-size: 1rem;
  margin-bottom: 0.3rem;
}

.Reward__game__pass_r1_image {
  width: 3rem;
  height: 3rem;
  border-radius: 0.5rem;
  background-color: var(--sidebar_secondory);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1.5rem;
}

/* Third Row data CSS */
.Reward--third--row--data {
  height: 25%;
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
}

.Reward--third--row--data--left {
  width: 39%;
  border: 2px dashed var(--sidebar_secondory-border);
  border-radius: 1rem;
  padding: 0.5rem;
  cursor: pointer;
  margin-top: 1rem;
  margin-right: 1.5rem;
}

.reward__File__Upload {
  text-align: center;
  color: var(--sidebar);
  font-weight: 600;
  margin-top: 0.5rem;
  font-size: 1rem;
}

.reward__File__Upload__desc {
  color: var(--dark);
  opacity: 60%;
  margin-top: 0.5rem;
  font-size: 0.7rem;
}

.Reward--third--row--data--right {
  width: 60%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  position: relative;
  border-radius: 1rem;
  background: var(--background) !important;
  padding: 1rem;
  margin-left: 2rem;
}
/* changing */
.third__row_right__image {
  width: 6rem;
  height: 6rem;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 1rem;
  background: var(--sidebar_secondory) !important;
  margin-top: 0.5rem;
}

.third__row_right__box {
  flex-grow: 1;
  height: 31%;
  display: flex;
  flex-direction: row;
  padding-top: 0.2rem;
  padding-left: 1rem;
  /* background: var(--background); */
  border-radius: 1rem;
  justify-content: space-between;
}

.third__row_right__text .third__row_right__text1 {
  color: var(--sidebar_secondory);
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0.3rem;
  margin-top: 0.5rem;
}

.third__row_right__text .third__row_right__text2 {
  color: var(--orange);
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
}

.third__row_right__text .third__row_right__text3 {
  color: var(--calenderBack);
  font-size: 1rem;
  line-height: 1.5;
  height: 4rem;
  overflow: auto;
}

/* save Button CSS */
.Reward__Save__BUTTON {
  border: none;
  outline: none;
  color: var(--pure);
  background-color: var(--orange);
  font-size: 1.2rem;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 12rem;
  padding: 1rem;
  border-end-end-radius: 1rem;
  border-top-left-radius: 1rem;
  cursor: pointer;
  height: 6.5%;
}

.close_dialog_wrapper__Reward__Creation {
  display: flex;
  justify-content: end;
}

/* .close_dialog__Reward__Creation {
  padding: 1rem;
  background-color: var(--pure);
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 1rem;
  font-weight: 600;
} */
.close_dialog__Reward__Creation {
  padding: 1rem;
  background-color: var(--pure);
  padding: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 1rem;
  cursor: pointer;
  position: absolute;
  top: 1rem;
  /* right: 25rem; */
  z-index: 100000;
  border: none;
  outline: none;
}
/* .close_dialog__sub__Creation {
  padding: 1rem;
  background-color: var(--pure);
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 1rem;
  font-weight: 600;
  margin-left: 44rem;
} */
.close_dialog__sub__Creation {
  padding: 1rem;
  background-color: var(--pure);
  padding: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
  /* border-radius: 0.5rem; */
  cursor: pointer;
  position: absolute;
  top: -31rem;
  right: -22rem;
  z-index: 100000;
  border: none;
  outline: none;
  position: relative;
}
.terms__condition__box {
  background: var(--sidebar);
  border-end-start-radius: 1rem;
  border-end-end-radius: 1rem;
  flex-grow: 1;
  height: 16.6rem;
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin-top: 1.3rem;
  padding-left: 1rem;
}
.third__row_right__text {
  width: 68%;
  margin-left: 1rem;
}
.reawrd_popup_label {
  color: var(--sidebar) !important;
  font-weight: 600;
}
.ant-radio-group-solid
  :where(
    .css-dev-only-do-not-override-dkbvqv
  ).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: var(--pure);
  background: var(--sidebar_secondory);
  border-color: var(--sidebar_secondory);
}
.reward_select_downarrow {
  position: absolute;
  right: 0.2rem;
  top: 0.6rem;
  z-index: 9;
  width: 1.1rem;
}
.reward_select_downarrow2 {
  position: absolute;
  right: 0.2rem;
  top: 1rem;
  z-index: 9;
  width: 1.1rem;
}
.reward_select_downarrow3 {
  position: absolute;
  right: 0.2rem;
  top: 1rem;
  z-index: 9;
  width: 1.1rem;
}
.third__row_right__image {
  width: 9rem;
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 1rem;
  background: var(--sidebar_secondory) !important;
  margin-top: 0.5rem;
}
.reward__termsACond {
  list-style-type: none;
}
.termsTextAreas {
  background: none;
  border: none;
  outline: none;
  /* color: var(--pure); */
  color: var(--dark_2);
  font-size: 1rem;
  font-family: "NimbusSanL", sans-serif;
  width: 98% !important;
  padding: 0.5rem; /* Use the font in your styles */
  padding-left: 0rem;
}
.termsTextAreas:focus {
  background: var(--inputFocus);
}
.terms_box_head {
  font-weight: 600;
  color: var(--pure);
  font-size: 1.2rem;
  margin-top: 1rem;
}
.terms__labels {
  /* color: var(--pure); */
  color: var(--dark_2);
  font-weight: bold;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: inline-block;
}

.terms__condition__box::-webkit-scrollbar {
  width: 0.3rem;
}

#description {
  font-family: "NimbusSanL", sans-serif;
}

/* Track */
.terms__condition__box::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.terms__condition__box::-webkit-scrollbar-thumb {
  background: var(--orange);
  border-radius: 10px;
}

/* Handle on hover */
.terms__condition__box::-webkit-scrollbar-thumb:hover {
  background: var(--orange);
}
.ant-radio-group-solid
  :where(.css-dkbvqv).ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ) {
  color: #fff !important;
  background: var(--sidebar_secondory) !important;
  border-color: var(--sidebar_secondory) !important;
}
.rewardSwiper {
  display: flex !important;
  justify-content: center !important;
  width: 100%;
  height: 100% !important;
  overflow-y: visible !important;
  position: relative !important;
}

.reward__navigation {
  background: none !important;
  border: none !important;
  outline: none !important;
  cursor: pointer !important;
}
.reward__prev {
  margin-right: 1rem;
}
.reward__next {
  margin-left: 1rem;
}
.Reward__game__pass__text .second-sentence {
  font-weight: 600 !important;
  width: 14rem;
  font-size: 1.2rem;
  color: var(--orange);
}
.Reward__game__pass__text .first-sentence {
  font-weight: bold !important;
  width: 23rem;
  font-size: 1.2rem;
}
.postDataView__description__reawrd {
  color: var(--grayShade1);
  font-weight: 500;
  padding-left: 1rem;
  height: 13rem;
  overflow: auto;
  margin-bottom: 1rem;
}
.reward__right_main__view__Container {
  border: 3px solid var(--collapseSubMenu);
  border-radius: 1rem;
  margin: 1rem auto;
  margin-bottom: 0rem;
  position: absolute;
  width: 100%;
}
