.profile__middle {
  width: 75%;
  height: 70.063rem;
}
.profile__usercard {
  width: 100%;
  /* height: 36.875rem; */
  background: var(--pure);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 1rem;
  padding: 1rem;
  position: relative;
}
.profile__userName {
  font-size: 2.5rem;
  color: var(--headingBlue);
  font-weight: 400;
  width: 100%;
  border: none;
  outline: none;
}
.profile__tagline {
  font-size: 1rem;
  color: var(--grayShade2);
  font-weight: 400;
  font-style: italic;
  border: none;
  outline: none;
  width: 100%;
}
.profile__image {
  margin-top: 1rem;
}
.profile__usercard {
  display: flex;
  justify-content: space-between;
}
.profile__usercard__left {
  width: 32%;
}
.profile__usercard__right {
  width: 63%;
}

.profile__progress {
  width: 90%;
  height: 1rem;
  background-color: #e4e4e4;
  margin-top: 1rem;
}
.profile__bio__header {
  display: flex;
  justify-content: space-between;
}
.profile__levelInfo {
  color: var(--sidebar_secondory);
  margin-top: 1rem;
  font-size: 1rem;
}

.profile__statics {
  margin-top: 1rem;
  display: flex;
}
.profile__bio__left {
  width: 90%;
}
.profile__bio__left > h1 {
  font-size: 1.5rem;
  color: var(--headingBlue);
  font-weight: 400;
}
.profile__bio__left > div {
  font-size: 1rem;
  color: var(--sidebar_secondory);
  font-weight: 700;
}
.profile__bio__left > p {
  font-size: 1rem;
  color: var(--orange);
  font-weight: 400;
}
.hagtag__input__profile {
  font-size: 1rem;
  color: var(--orange);
  font-weight: 400;
  border: none;
  outline: none;
}
.profile__bio__description {
  font-size: 1rem;
  color: var(--dark_2);
  font-weight: 400;
  margin-top: 1.5rem;
}
.profile__coming__soon {
  background: var(--sidebar_secondory);
  color: var(--pure);
  margin-top: 1.5rem;
  border-radius: 1rem;
  height: 14rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 5rem;
  margin-bottom: 3.5rem;
}
.profile__tab__section {
  margin-top: 1rem;
}
.profile__tabcard {
  width: 49%;
  height: 6rem;
  background: var(--pure);
  border-radius: 0.8rem;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  cursor:'pointer'
}
.profile__tabcard > img {
  width: 20%;
  border-radius: 0.8rem;
  height: 100%;
}
.profile__tab__body {
  /* display: flex;
  justify-content: space-between;
  flex-wrap: wrap; */
  background: var(--sidebar_secondory);
  color: var(--pure);
  margin-top: 1.5rem;
  border-radius: 1rem;
  height: 14rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 5rem;
  margin-bottom: 3.5rem;
  width: 59rem;
}
.tabcard__user {
  color: var(--orange);
  font-size: 1rem;
  font-weight: 400;
  align-items: start;
}
.tabcard__comment {
  font-size: 1.1rem;
  color: var(--paragraphText);
  font-weight: 400;
}

.tabcard__right {
  padding-left: 1rem;
}
.tabcard__right__time {
  text-align: end;
}
.profile__submission__card {
  background: var(--pure);
  width: 22rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 1rem;
  padding: 1rem;
  height: 37rem;
  padding-bottom: 3rem;
}
.all__submission__card {
  /* background: var(--pure); */
  /* width: 22rem; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 1rem;
  padding: 1rem;
  /* height: 37rem; */
  padding-bottom: 3rem;
}
.butttt{
  bottom: 0;
  right: 0;
  background: var(--orange);
  color: var(--pure);
  border: none;
  outline: none;
  padding: 0.5rem;
  font-size: 1.2rem;
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  cursor: pointer;
  margin-top: 0.5rem;
}
.profile__submission__heading {
  font-size: 1.5rem;
  color: var(--headingBlue);
  font-weight: 400;
}
.profile__sbmn {
  width: 100%;
  height: 8.5rem;
  border-radius: 0.8rem;
  background-color: var(--sidebar_secondory);
}
.profile__submission__body {
  margin-top: 1rem;
  height: 92%;
  overflow: auto;
}
.prf__sbmn__left {
  display: flex;
  justify-content: space-between;
}
.prf__sbmn__project {
  color: var(--pure);
}
.prf__sbmn__right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.prf__sbmn__status {
  font-size: 0.813rem;
  color: var(--pure);
}
.prf__sbmn__total {
  font-size: 0.813rem;
  color: var(--pure);
}
.prf__sbmn__tgs {
  font-size: 1rem;
  color: var(--orange);
}
.Main {
  width: 90%;
  margin: 1rem auto;
  overflow-y: scroll;
  height: 85%;
}
.profile__blueCard {
  background: var(--sidebar_secondory);
  border-radius: 1rem;
  width: 100%;
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
}
.profile__blueCard::-webkit-scrollbar {
  width: 1rem;
  /* background: var(--orange); */
}
#scrollableDiv::-webkit-scrollbar {
  width: 1rem;
  /* background: var(--orange); */
}
.profile__profileImage {
  display: flex;
  align-items: center;
}
.profile__profileImage > b {
  display: inline-block;
  margin-left: 0.5rem;
  color: var(--pure);
  font-weight: 400;
  font-size: 1rem;
}
.profile__profileImage > img {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  object-fit: cover;
}
.profile__meterDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.profile__meterDiv span {
  color: var(--pure);
  font-size: 0.7rem;
}
.profile__basicInfo {
  display: flex;
}
.profile__basicInfo > div {
  margin-right: 1rem;
}
.profile__basicInfo > img {
  cursor: pointer;
}
.profile__submission__body {
  height: 80%;
}
.profile__bio__description__textArea {
  font-size: 1rem;
  line-height: 1.7rem;
  color: var(--paragraphText);
  font-weight: 400;
  border: none;
  outline: none;
}
.profile__bio__description__textArea:focus {
  background: var(--inputFocus);
  color: var(--pure);
}
.profile__userName:focus {
  background: var(--inputFocus);
  color: var(--pure);
}
.profile__tagline:focus {
  background: var(--inputFocus);
  color: var(--pure);
}
.profile__category__ddl {
  background: var(--pure);
  position: absolute;
  top: 2rem;
  left: 0;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  border-radius: 1rem;
  padding: 1rem;
  list-style-type: none;
  width: 17rem;
  z-index: 9 !important;
}
.profile__category__ddl > li {
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  cursor: pointer;
  border-radius: 0.5rem;
}
.profile__category__ddl > li:hover {
  background: var(--sidebar_secondory);
  color: var(--pure);
  padding: 1rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.profile_username_skeleton {
  width: 95% !important;
  height: 46.67px;
}
.profile_tagline_skeleton {
  width: 95% !important;
  height: 17.78px !important;
}
.profile__image__skeleton {
  width: 90% !important;
  height: 20.5rem !important;
}
.profile__category__skeleton {
  height: 1rem !important;
}
.profile__bio__skeleton {
  width: 100% !important;
  height: 7rem !important;
}
.hagtag__input__profile:focus {
  background-color: var(--inputFocus);
  color: var(--pure);
}
.mainEDitprofile__SaveBTN {
  position: absolute;
  bottom: 0;
  right: 0;
  background: var(--orange);
  color: var(--pure);
  border: none;
  outline: none;
  padding: 1rem;
  font-size: 1.2rem;
  border-end-end-radius: 1rem;
  border-top-left-radius: 1rem;
  cursor: pointer;
  margin-top: 3rem;
}

.post-update-button {
  background: var(--orange);
  color: var(--pure);
  border: none;
  outline: none;
  padding: 1rem;
  font-size: 1.2rem;
  border-end-end-radius: 1rem;
  border-top-left-radius: 1rem;
  cursor: pointer;
  position: absolute;
  margin-top: 1rem;
  bottom: 0;
}

.profile-alert {
  position: fixed;
  top: 5%;
  left: 50%;
  right: 4rem;
  width: 20%;
  height:30%;
  text-align: center;
  z-index: 1000;
}
.setting__main {
  display: flex;
  justify-content: center; /* Centers children horizontally */
  align-items: center; /* Centers children vertically */
}

.setting_wrapper {
  width: 50%;
  height: 100%;
  background: var(--pure);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 1rem;
  padding: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
}

.setting_wrapper label {
  width: 9.375rem; /* Adjust the width as needed */
}

.setting_wrapper .ant-form-item-control-input {
  flex: 1; /* Take up remaining space */
}


.ant-btn-primary {
  color: #fff;
  background: #e98326;
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
}
.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  color: #fff;
  background: #e98326;
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
}
.ant-input-outlined:hover {
  border-color: #e98326;
  background-color: #ffffff;
}
.ant-input-outlined:focus,.ant-input-outlined:focus-within {
  border-color: #e98326;
  box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
  outline: 0;
  background-color: #ffffff;
}
@media screen and (min-width: 1100px) and (max-width: 1366px) {
  .profile__submission__card {
    background: var(--pure);
    width: 17rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 1rem;
    padding: 1rem;
    height: 37rem;
    padding-bottom: 3rem;
  }
}