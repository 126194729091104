.sidebar {
  width: 15em;
  background-color: var(--sidebar);
  height: 100vh;
  border-top-right-radius: 1em;
  border-bottom-right-radius: 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  overflow-y: auto;
  overflow-x: hidden;
}
.c_sidebar {
  width: 5.4em;
  background-color: var(--sidebar);
  height: 100vh;
  border-top-right-radius: 1em;
  border-bottom-right-radius: 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  overflow: auto;
}

.c_sidebar_bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.pureBG {
  background-color: var(--pure);
  width: 100%;
  text-align: center;
  padding: 1em;
}
.c_sidebar::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
}

.c_linksList {
  margin-top: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.branding {
  padding-left: 1.2em;
  padding-right: 1.2em;
}

.logo {
  color: var(--pure);
  font-size: 2em;
  font-weight: bold;
}
.c_logo {
  color: var(--pure);
  font-size: 2em;
  font-weight: bold;
  margin-top: 0.5em;
}
.tagline {
  color: var(--pure);
  font-weight: 500;
  font-size: 0.8em;
}

.highlight {
  color: var(--orange);
}

.inner {
  margin-top: 1em;
}
.c_inner {
  width: 100% !important;
}

.profileImg {
  width: 35%;
}

.profileImg > img {
  width: 5em;
  height: 5em;
  border-radius: 1em;
}

.profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1em;
  margin-bottom: 1em;
}

.c_profile {
  width: 2.1em;
  height: 2.1em;
  border-radius: 1em;
  margin-top: 1em;
  object-fit: cover;
}

.user > h2 {
  color: var(--pure);
  font-size: 1em;
}

.icons {
  color: var(--orange) !important;
}

.badge {
  background: none !important;
  color: var(--pure) !important;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.c_badges {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  margin-top: 2em;
}

.diff {
  background-color: var(--pure);
  padding-top: 1em;
  padding-bottom: 1em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  position: relative;
  align-items: center;
}
.notiIcon {
  margin-left: 0.5em;
}
.c_diff {
  background-color: var(--pure);
  padding-top: 1em;
  padding-bottom: 1em;
  margin-top: 2em;
  position: relative;
  align-items: center;
}

.stats {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: grey;
  margin-left: 2em;
}
.firstStats {
  margin-left: 0em !important;
}
.c_stats {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: grey;
}

.st_text {
  font-size: 0.7em;
}

.toggleB {
  background: var(--orange);
  color: var(--pure);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  width: 1em;
  height: 2.5em;
  border-top-left-radius: 0.5em;
  border-bottom-left-radius: 0.5em;
  cursor: pointer;
}

.toggleBIcon {
  font-size: 7em !important;
  width: 30px !important;
}

.sidebarLinkInner {
  display: flex;
  align-items: center;
  color: var(--pure);
}

.sidebarLink {
  display: flex;
  padding-left: 1.5em;
  padding-top: 1em;
  padding-bottom: 1em;
  margin-top: 0.3em;
}

.c_linksList > a {
  display: inline-block;
  padding-top: 1em;
  color: var(--pure);
  padding-bottom: 1em;
}

.sidebarLink:hover {
  background: var(--sidebar_secondory);
}
.linkList_P {
  margin-top: 3em;
  margin-bottom: 3em;
}

.sidebarLinkText {
  margin-left: 1em;
  font-size: 1.1em;
}

.sidebar_bottom {
  width: 100%;
  border-bottom-right-radius: 1em;
}

.input {
  border: none;
  outline: none;
  padding: 1em;
}
.input::placeholder {
  font-size: 1.2em;
}

.ex_Li {
  font-size: 0.7em;
  border-right: 2px solid var(--pure);
  padding-right: 0.5em;
  padding-left: 0.5em;
}

.sidebar_bottom_search {
  background-color: var(--pure);
}
.extraPages {
  display: flex;
  justify-content: center;
  margin-bottom: 1em;
}
.extraPages > a,
.extraPages {
  color: var(--pure);
}

.c_links {
  padding-left: 0.7em;
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-top: 0.3em;
}
.c_links:hover {
  background: var(--sidebar_secondory) !important;
}
