.chat_header {
  border-top-right-radius: 1em;
  border-top-left-radius: 1em;
  background-color: var(--sidebar_secondory);
 
}
.ewqr{
  display: flex;
  justify-content: center;
  align-items: center; 
  margin-top: 0.5rem;
}
.chat_header2 {
  background-color: var(--sidebar_secondory);
  position: sticky;
  top: 3%;
  border-radius: 1rem;
  width: fit-content;
  padding: 0.3rem;
  align-items: center;
}

.nav_text{
  color: var(--pure);
  font-weight: 700;
  font-size: 1rem;
}
.chat_footer {
  border-end-end-radius: 1em;
  border-end-start-radius: 1em;
  background-color: var(--sidebar_secondory);
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 0.6em;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.chat_header_heading {
  color: var(--pure);
}
.chat_wrapper {
  position: relative;
  height: 100% !important;
}
.chat_header_top {
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  padding-left: 1em;
  padding-right: 1em;
  border-bottom: 3px solid rgba(0, 0, 0, 0.164);
}
.chat_header_bottom {
  padding: 1em;
}
.chat_header_heading {
  font-size: 1.5rem;
  font-weight: 400;
}
.chat_header_sortByDate_label {
  color: var(--pure);
  font-size: 1rem;
}
.chat_header_dateSelector {
  margin-left: 0.5rem;
  font-size: 1rem;
  color: var(--chat_dateSelector);
}
.chatMainBody {
  padding-right: 1em;
  padding-left: 1em;
  overflow: auto;
  height: 69%;
}
.chat_bubble {
  width: 16em;
  border-radius: 1em;
  padding: 0.5em;
}
.send_media {
  display: flex;
  justify-content: end;
  margin-top: 1em;
}
.receive_media {
  display: flex;
  justify-content: start;
  margin-top: 1em;
}
.very_dark_blue_bg {
  background: var(--veryDarkblue);
  border-radius: 1em;
  margin-bottom: -1rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.c_b_profile {
  width: 2em;
  height: 2em;
  border-radius: 1em;
}
.c_b_profileName {
  padding-left: 0.5em;
}
.c_b_profileName > label {
  font-size: 0.875rem;
  color: var(--pure);
  font-weight: bold;
}
.c_b_profileName > div {
  font-size: 0.75rem;
  color: var(--chatStatus);
  margin-top: 0.1rem;
}
.chat_time {
  font-size: 0.75rem;
  color: var(--chatStatus);
}
.c_b_likesOptions {
  display: flex;
  justify-content: end;
}
.chat_bubble_likeicon {
  color: var(--orange);
  font-size: 0.75rem !important;
  margin-top: 0.2em;
}
.chat_text {
  font-size: 0.8rem !important;
  color: var(--chatStatus);
  margin-top: 0.5em;
  width: 90%;
}
.send_chat {
  display: flex;
  justify-content: end;
}
.send_chat > .chat_bubble {
  background: var(--veryDarkblue);
}
.receive_chat {
  display: flex;
  justify-content: start;
}
.receive_chat > .chat_bubble {
  background: var(--receiveChat);
}
.chat_bubble_warpper {
  margin-top: 1.5em;
}
.chat_add_icon {
  font-size: 2.25rem;
  color: var(--chatStatus);
  margin-right: 0.5em;
  cursor: pointer;
}
.chat_text_area {
  background: var(--pure);
  width: 98%;
  border-radius: 1em;
}
.chat_field {
  height: 100%;
  padding: 1rem;
  width: 80%;
  border: none;
  outline: none;
  border-end-start-radius: 1em;
  border-top-left-radius: 1em;
  font-size: 1.2rem;
}
.footer_chat_icons {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  display: flex;
}
.emoji_chat_icon {
  color: var(--orange) !important;
  font-size: 2em !important;
  padding-right: 0.1em;
  border-right: 2px solid var(--sidebar);
  margin-right: 0.2em;
  cursor: pointer;
}
.send_chat_icon {
  color: var(--orange) !important;
  font-size: 2em !important;
  padding-right: 0.1em;
  padding-left: 0.5rem;
}
.chat_file {
  color: var(--pure) !important;
  padding: 1rem !important;
  display: inline-block !important;
  margin-top: 1rem !important;
}
.date_separator{
  margin-top: 1rem;
  color: var(--pure);
  font-weight: 600;
}
