.FM__wrapper--child {
  width: 100%;
  height: 56rem;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 1rem;
  background: var(--pure) !important;
  padding: 2rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.FM__wrapper {
  display: flex;
  justify-content: space-between;
  position: relative;
  height: 56rem;
}
.heightOver___ {
  height: auto;
}
.FM__60 {
  width: 60%;
}
.FM__36 {
  width: 36%;
}
.FM__DRopper__Main {
  width: 100%;
  border: 2px dashed var(--sidebar_secondory-border);
  border-radius: 1rem;
  padding: 2rem;
  cursor: pointer;
}
.FM__DROpper_image {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.FM__DROpper_image > img {
  width: 30%;
}
.FM__DROPPer__show {
  text-align: center;
  color: var(--sidebar);
  font-weight: 600;
  margin-top: 0.5rem;
}
.FM__Dropper__desc {
  color: var(--dark);
  opacity: 60%;
  margin-top: 0.5rem;
}
.FM__FP__wrapper {
  border-radius: 1rem;
  background-color: var(--sidebar_secondory-border);
  padding: 1rem;
}
.FM__FP__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.FP_icon {
  color: var(--orange);
  cursor: pointer;
}
.FP__COunts {
  color: var(--headingBlue);
}
.FM__FP__wrapper {
  margin-top: 1rem;
}
.FM__FP__Size {
  font-size: small !important;
  color: var(--sidebar);
}
.FM__FP__progress {
  background: var(--headingBlue);
  height: 0.3rem;
  border-radius: 1rem;
  margin-top: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.FM__FP__progress__Animate {
  background: var(--sidebar);
  height: 100%;
  width: 5%;
  border-radius: 1rem;
}
.FM__IM_CARD_WRPPER {
  display: flex;
  flex-wrap: wrap;
  height: 22rem;
  overflow: auto;
}
.FM__imageCard {
  border-radius: 1rem;
  background-color: var(--sidebar_secondory-border);
  width: 12rem;
  height: 9rem;
  position: relative;
  cursor: move;
  margin-right: 1rem;
}
.FM__IC__Order {
  display: inline-block;
  color: var(--pure);
  background: var(--headingBlue);
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.FM__IC__Order > b {
  font-size: 0.8rem;
}
.FM__IC__header {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
}
.FM__IC__Remove {
  display: inline-block;
  background-color: var(--orange);
  color: var(--pure);
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
}
.FM__IC__footer {
  background: var(--sidebar);
  color: var(--pure);
  text-align: center;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  border-end-start-radius: 1rem;
  border-end-end-radius: 1rem;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}
.FM__LEFT__info {
  margin-top: 1rem;
  color: var(--dark);
  font-weight: 600;
  font-size: 1.2rem;
  opacity: 70%;
}
.TAB_BtN {
  width: 50%;
  padding: 1rem;
  font-size: 1.4rem;
  color: var(--headingBlue);
  border: none;
  cursor: pointer;
}
.TAB_Left {
  border-top-left-radius: 1rem;
  border-end-start-radius: 1rem;
}
.TAB_Right {
  border-top-right-radius: 1rem;
  border-end-end-radius: 1rem;
}
.FM__act__TAB {
  background-color: var(--headingBlue);
  color: var(--pure);
}
.FM__F_TitleSEc {
  border-radius: 1rem;
  background-color: var(--sidebar_secondory-border);
  padding: 1rem;
  margin-top: 0.5rem;
}
.FM__F_LAbel {
  color: var(--headingBlue);
  font-size: 1.3rem;
}
.FM__Input {
  margin-top: 1rem;
}
.FM__DESC {
  margin-top: 1.3rem;
}
.FM__BUTTON {
  border: none;
  outline: none;
  color: var(--pure);
  background-color: var(--orange);
  font-size: 1.2rem;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 15rem;
  padding: 1.2rem;
  border-end-end-radius: 1rem;
  border-top-left-radius: 1rem;
  cursor: pointer;
}
.FM__T2__CapacityMedia {
  width: 12rem;
  width: 12rem;
  border-radius: 1rem;
}
.FM__Center {
  text-align: center;
}
.FM__Stored_Tab {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}
.TAB_BtN_ {
  padding: 1rem;
  font-size: 1.2rem;
  color: var(--headingBlue);
  border: none;
  cursor: pointer;
  width: 9rem;
}
.FM__act__TAB_ {
  background-color: var(--headingBlue);
  color: var(--pure);
}
.FM__stored_grid {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  height: 31rem;
  position: relative; /* Add position relative */
}

.no_data {
  position: absolute; /* Change position to absolute */
  top: 50%; /* Position 50% from the top */
  left: 50%; /* Position 50% from the left */
  transform: translate(-50%, -50%); /* Move the element up and left by 50% of its width and height respectively */
}

.bg-pure {
  background: var(--pure);
}
.radius-1 {
  border-radius: 1rem;
  padding: 2rem;
}
.mediaUPloaer___card__media {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  z-index: 0 !important;
}
.mediaUPloaer___card__ado {
  display: flex;
  justify-content: center;
  height: 7rem;
}
.MEDI_Wploadr_application {
  display: inline-block;
  width: 100%;
  text-align: center;
}
.img__MEdiaUPloder {
  border-radius: 1rem;
  margin-top: 1rem;
}
.FM_SELcted_MEDIA_Div {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}
.FM__Relative__PArent {
  position: relative;
  width: 80%;
}
.mainEditAreaMiddleRight__Lft__r2 {
  width: 69.5% !important;
}
.mainEditAreaMiddleRight__Lft__r2__nOcollapse {
  width: 67% !important;
}
.mediaAsset__delete__popup {
  background: var(--pure) !important;
  padding: 1rem;
  width: 22rem !important;
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.mediaAsset__delete__popup2 {
  background: var(--pure) !important;
  padding: 1rem;
  width: 34rem !important;
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.close_BTNmediaAsset__delete__popup {
  display: flex;
  justify-content: end;
}
.close_BTNmediaAsset__delete__popup > button {
  font-size: 1.2rem;
  font-weight: bold;
  background: none;
  border: none;
  cursor: pointer;
}
.close__FM_alert {
  font-size: 1.2rem;
  font-weight: bold;
  background: none;
  border: none;
  cursor: pointer;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  margin-left: 2rem;
}
.close_BTNmediaAsset__delete__popup > button:hover {
  background: grey;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
}
.delete__asset_confirm {
  margin-top: 0.5rem;
  width: 5rem;
  cursor: pointer;
  padding: 0.2rem;
  border-radius: 0.4rem;
  font-size: 1.2rem;
}
.delete__asset_confirm_cancle {
  margin-left: 0.5rem;
  background: none;
  border: 1px solid var(--orange);
  color: var(--orange);
}
.delete__asset_confirm_delete {
  margin-left: 0.5rem;
  border: none;
  background: var(--orange);
  color: var(--pure);
}
.FM_SELcted_MEDIA_Div {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  height: 14.8rem;
}
.upload-alert {
  position: fixed;
  top: 10px;
  right: 4rem;
  width: 20%;
  text-align: center;
  z-index: 1000;
}
.asset-deleted-alert {
  position: fixed;
  top: 1rem;
  right: 20rem;
  width: 20%;
  text-align: center;
  z-index: 1000;
}
.content {
  color: #e98326 !important;
}
/* .close__FM_message {
  text-transform: uppercase;
} */
