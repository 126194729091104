.reward__hover__card {
  position: absolute;
  background: var(--pure);
  width: 45rem;
  /* height: 20rem; */
  height: auto;
  z-index: 999;
  top: -21rem;
  /* right: -47rem; */
  right: -20rem;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  border-radius: 1rem;
  display: none;
}
.terms_for_rewards {
  margin-right: 38rem; /* Auto margin on left and right to horizontally center */
  margin-left: auto;
  margin-top: 1.5rem;
  background-color: var(--sidebar);
  height: 3rem;
  width: 10rem;
  border-radius: 0.3rem;
  display: flex; /* Use flexbox */
  justify-content: center; /* Start from the left */
  align-items: center; /* Center vertically */
  color: var(--pure);
  cursor: pointer;
}
.terms_close {
  cursor: pointer;
  color: var(--pure);
  font-size: 1.5rem;
}
.terms_close p {
  margin-left: 95%;
}
/* .reward__hover__card__top {
  display: flex;
  justify-content: space-between;
} */
.reward__hover__card__top__left {
  width: 60%;
  display: flex;
}
.reward__hover__card__top__right {
  width: 35%;
}
.reward_hover_card__image {
  /* background: var(--sidebar_secondory); */
  width: 8rem;
  height: 8rem;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.reward_hover_card__meta {
  margin-left: 2rem;
  margin-top: 0.5rem;
}
.reward_hover_card__meta_name {
  color: var(--sidebar_secondory);
  font-size: 1.3rem;
  font-weight: bold;
}
.reward__hover__card__top__right div {
  display: flex;
  margin-top: 0.5rem;
}
.reward__hover__card__top__right h2 {
  color: var(--sidebar_secondory);
  font-size: 1.1rem;
  font-weight: bolder;
}
.reward__hover__card__top__right b {
  display: inline-block;
  margin-left: 0.5rem;
  font-weight: 600;
  color: var(--paragraphText);
}
.reward_hover_card__meta_category {
  color: var(--orange);
  font-size: 1.4rem;
  font-weight: bold;
  margin-top: 0.5rem;
}
.reward__hover__card__description {
  color: var(--paragraphText);
  opacity: 90%;
  line-height: 1.9rem;
  font-size: 1.1rem;
  margin-top: 1.5rem;
  font-weight: 500;
  /* height: 8rem; */
  height: auto;
  /* overflow: auto; */
}
.reward__hover__card__top {
  height: 50%;
  padding: 1rem;
}
.reward__hover__card__bottom {
  background-color: var(--sidebar);
  height: 80%;
  border-radius: 1rem;
  width: 100%;
  /* overflow: auto; */
  /* border-end-start-radius: 1rem;
  border-end-end-radius: 1rem; */
}
.reward__hover__card__terms_and_condition {
  width: 95%;
  margin: auto;
  padding-top: 1rem;
  padding-bottom: 1rem;
  height: 80%;
  overflow: auto;
  scrollbar-width: thin;
}
.reward__hover__card__terms_and_condition::-webkit-scrollbar {
  width: 0.5rem !important;
}
.reward__hover__card__terms_and_condition::-webkit-scrollbar-thumb {
  background: var(--orange);
  border-radius: 0.5rem;
}
.reward__hover__card__terms_and_condition::-moz-scrollbar {
  width: 0.5rem !important;
}

.reward__hover__card__terms_and_condition::-moz-scrollbar-thumb {
  background: var(--orange);
  border-radius: 0.5rem;
}
.reward__hover__card__terms_and_condition h1 {
  color: var(--pure);
  font-size: 1.5rem;
}
.reward__hover__card__terms_and_condition h2 {
  color: var(--pure);
  font-size: 1.1rem;
}
.reward__hover__card__terms_and_condition p {
  color: var(--pure);
  font-size: 1em;
  font-weight: 500;
  margin-top: 0.5rem;
}
