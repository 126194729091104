.InteractionCard {
  width: 30rem;
  background: var(--pure);
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  height: 47rem;
  position: absolute;
  z-index: 99;
  top: 5.5rem;
}
.Heading {
  font-size: 1.8rem;
  color: var(--headingBlue);
  font-weight: 400;
}
.Header {
  width: 85%;
  margin: auto;
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
}
.Tabs {
  display: flex;
}
.tab {
  cursor: pointer;
  margin-right: 0.5rem;
  display: flex;
  justify-content: start;
  height: 3rem;
}
.tab > span {
  color: var(--orange);
  font-size: 0.625rem;
  font-weight: 700;
}
.tabActive > img {
  border-bottom: 3px solid var(--sidebar_secondory);
}
.Main {
  width: 90%;
  margin: 1rem auto;
  overflow-y: scroll;
  height: 85%;
}
.blueCard {
  background: var(--sidebar_secondory);
  border-radius: 1rem;
  width: 100%;
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
}
.blueCard::-webkit-scrollbar{
  width: 1rem;
  /* background: var(--orange); */
}
#scrollableDiv::-webkit-scrollbar{
  width: 1rem;
  /* background: var(--orange); */
}
.profileImage {
  display: flex;
  align-items: center;
}
.profileImage > b {
  display: inline-block;
  margin-left: 0.5rem;
  color:var(--pure);
  font-weight: 400;
  font-size: 1rem;
}
.profileImage > img {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  object-fit: cover;
}
.meterDiv{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.meterDiv span{
  color: var(--pure);
  font-size: 0.7rem;
}
.basicInfo{
  display: flex;
}
.basicInfo>div{
  margin-right: 1rem;
}
.basicInfo>img{
  cursor: pointer;
}
