.charity__body {
    width: 15rem;
    left: -5rem !important;
}

.upload-alert {
    position: fixed;
    top: 10px;
    right: 4rem;
    width: 20%;
    text-align: center;
    z-index: 1000;
}