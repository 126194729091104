.register__dialog__main__wrapper {
  display: flex;
  position: relative;
  border-radius: 1rem;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  margin-top: 6rem;
}

.register__dialog__left__side {
  width: 27.438rem;
  height: 45.125rem;
  border-top-left-radius: 1.25rem;
  border-bottom-left-radius: 1.25rem;
  background-color: #f4f5f6;
  padding: 2rem;
  display: flex;
  flex-direction: column;
}

.register_heading {
  width: 23rem;
  color: #00537c;
  font-size: 2.1rem;
  font-weight: 400;
  line-height: 3rem;
  margin-bottom: 3rem;
}

.register__with__social {
  display: flex;
}

.register__with__social_section {
  border: 3px solid var(--pure);
  width: 3rem;
  padding: 0.2rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  padding-bottom: 0;
  border-radius: 0.3rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-left: 1.5rem;
}

.register__social_section {
  border: 1.8px solid #00537c;
  width: 3rem;
  padding: 0.2rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  padding-bottom: 0;
  border-radius: 0.8rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-left: 1.5rem;
}

.register__or {
  font-size: 1.313rem;
  color: #262626;
  text-align: center;
  margin-top: 1.5rem;
}

.register_btn__dialog {
  margin-top: 2rem;
  background: var(--orange);
  color: var(--pure);
  border: none;
  width: 50%;
  height: 3rem;
  font-size: 1rem;
  font-weight: 400;
}

.register_btn__dialog:hover {
  color: var(--pure) !important;
}

.account__have {
  font-size: 1rem;
  line-height: 1.188rem;
  color: #262626;
  font-weight: 400;
  text-align: left;
  margin-top: 2rem;
}

.account__have span {
  font-size: 1rem;
  line-height: 1.188rem;
  color: var(--orange);
  font-weight: 500;
  text-align: left;
  margin-top: 2rem;
  cursor: pointer;
}

/* .register__INPUT__dialog {
    margin-top: 1rem;
}

.register__INPUT__dialog>label {
    color: #00537c;
    font-size: 1rem;
    line-height: 1.125rem;
    font-weight: 700;
}

.register__INPUT__input__dialog {
    margin-top: 0.5rem;
    height: 2.5rem;
    outline: none;
} */
.register__INPUT__dialog {
  margin-top: 1rem;
  position: relative;
}

.register__INPUT__dialog.focused > label,
.register__INPUT__dialog > input:not(:placeholder-shown) + label {
  transform: translateY(-80%) scale(1);
  font-size: 1rem;
}

.register__INPUT__dialog > label {
  position: absolute;
  top: 50%;
  left: 2rem;
  transform: translateY(-50%);
  color: #00537c;
  font-size: 1rem;
  line-height: 1.125rem;
  font-weight: 700;
  pointer-events: none;
  transition: transform 0.3s, font-size 0.3s;
}

.register__INPUT__input__dialog {
  width: 20.68rem;
  height: 3.188rem;
  padding: 10px;
  font-size: 0.875rem;
  border: none;
  border-radius: 4px;
  outline: none;
  margin-top: 1rem;
  padding-top: 2rem !important;
  /* Add a margin-top to create space between input and label */
}

.register__INPUT__input__dialog::placeholder {
  color: #00537c;
  font-size: 1rem;
  line-height: 1.125rem;
  font-weight: 700;
  margin-bottom: 10px;
}

.register__dialog__right__side {
  width: 27.25rem;
  height: 45.125rem;
  border-top-right-radius: 1.25rem;
  border-bottom-right-radius: 1.25rem;
  background-color: grey;
}

.register__dialog__right__side img {
  border-top-right-radius: 1.25rem;
  border-bottom-right-radius: 1.25rem;
}
.password-toggle-icon{
  position: absolute;
  top: 78%;
  left: 85%;
  transform: translateY(-50%);
  color: #00537c;
  font-size: 1rem;
  line-height: 1.125rem;
  font-weight: 700;
}

.register__checkbox__dialog {
  margin-top: 0.7rem;
}

.register__checkbox__dialog label {
  font-size: 1rem;
  line-height: 1.188px;
  color: #262626;
  font-weight: 400;
}

.register__checkbox__dialog input {
  margin-right: 0.2rem;
}

.register__dialog__close {
  position: absolute;
  right: 3rem;
  top: 3rem;
  cursor: pointer;
  font-size: 1.5rem;
  color: #ffffff;
  background: none;
  border: none;
  outline: none;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  font-weight: 400;
}
