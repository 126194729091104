.detailCard__wrapper {
  width: 30rem;
  background: var(--pure);
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
  height: 45rem;
  position: absolute;
  left: -10rem;
  z-index: 99;
}

.detailCard__wrapper_inner {
  position: relative;
  height: 100%;
}

.detailCard__top {
  padding: 2rem;
}

.detailCard_lg>img {
  width: 4.5rem;
  height: 3.5rem;
  border-radius: 0.8rem;
}

.detailCard_bn {
  font-size: 1.8rem;
  color: var(--headingBlue);
  font-weight: 400;
}

.detailCard_top__row_description {
  font-size: 1rem;
  line-height: 22px;
  color: var(--paragraphText);
  font-weight: 500;
  margin-top: 1rem;
}

.detailCard__main_heading {
  font-size: 1.5rem;
  line-height: 18px;
  color: var(--headingBlue);
  font-weight: 400;
  margin-top: 1rem;
}

.detailCard__main_heading {
  text-align: center;
}

.detailCard_meterp_span {
  color: var(--orange);
  font-size: 0.7rem;
  font-weight: 400;
  text-align: center;
}

.detailCard_meterp {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: -4.5rem;
}

.detailCard__main_coins {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.detailCard__coin {
  margin-left: 0.7rem;
}

.detailCard__main_points {
  color: var(--orange);
  font-size: 0.625rem;
  font-weight: 700;
}

.detailCard__coin_section {
  display: flex;
  align-items: start;
}

.detailCard__carousel__wrapper {
  margin-top: 2rem;
}

.relatedProject__mainCarousel {
  width: 85%;
  background-color: var(--sidebar_secondory);
  height: 10rem;
  border-radius: 1rem;
}

.relatedProjects_main {
  display: flex;
  justify-content: space-evenly;
}

.relatedProject__left {
  width: 40%;
  height: 100%;
  display: flex;
  position: relative;
}

.relatedProject__left>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.8rem;
}

.relatedProject__mainCarousel {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-right: 2rem;
  padding-left: 2rem;
}

.avtar__group {
  position: absolute;
  bottom: 0;
  right: -3rem;
}

.relatedProject__mainCarousel {
  display: flex;
  justify-content: space-between;
}

.relatedProject__right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.relatedProject__right>div {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.project_name {
  font-size: 1.2rem;
  color: var(--pure);
  font-weight: 400;
}

.hashtags {
  color: var(--orange);
  font-weight: 600;
}

.phase_name,
.phase_time {
  color: var(--pure);
  font-size: 1rem;
}

.detailCard__greenCard {
  background: var(--greenType);
  color: var(--pure);
  padding: 1rem;
  border-radius: 1rem;
  width: 85%;
}

.detailCard__greenCard>p>b {
  color: var(--orange);
}

.detailCard__greenCard>p {
  font-size: 1.2rem;
}

.detailCard__greenCard_wrapper {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.buttonType {
  background: var(--sidebar);
  color: var(--pure);
  border: none;
  outline: none;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  border-top-left-radius: 1rem;
  border-end-end-radius: 0.9rem;
  font-size: 1.2rem;
}

.slidecontainer {
  width: 100%;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
  margin-top: 1rem;
  border-radius: 0.5rem;
  height:3.5rem;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 4rem;
  height: 4rem;
  background: url('../../../public/tests/icons/goldcoin.svg') center center no-repeat;
  background-size: cover;
  cursor: pointer;
  border-radius: 50%; 
}

.slider::-moz-range-thumb {
  width: 4rem;
  height: 4px;
  background: url('../../../public/tests/icons/goldcoin.svg') center center no-repeat;
  background-size: cover;
  cursor: pointer;
  border-radius: 50%;
}
/* Add this to your CSS module */
.sliderValue {
  position: absolute;
  left: 10px; /* Adjust the left position as needed */
  top: -25px; /* Adjust the top position as needed */
  color: white;
  font-size: 14px; /* Adjust the font size as needed */
}
