.notification__minga {
  position: absolute;
  top: 13rem;
  left: 10rem;
  padding: 1rem;
  border-radius: 1rem;
  z-index: 999;
  width: 30rem;
  height: 44rem;
  background-color: var(--pure);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}
.notification__minga__header {
  display: flex;
  justify-content: flex-end;
}
.notification__minga__header > button {
  border: none !important;
  outline: none !important;
  cursor: pointer;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  font-weight: bold;
  font-size: 1rem;
  border-radius: 0.3rem;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}
.friend__notify {
  background: var(--pure);
  border-radius: 0.3rem;
  padding: 0.6rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  width: 100%;
}
.friend__notify__middle {
  width: 62%;
  margin-left: 1rem;
}
.friend__notify__profile > img {
  width: 4rem;
  border-radius: 50%;
}
.friend__notify__time {
  font-weight: bold;
  color: var(--sidebar_secondory);
  font-size: 0.811rem;
  margin-top: 0.5rem;
}
.friend__notify__Accept {
  background: var(--sidebar_secondory);
  color: var(--pure);
}
.friend__notify {
  margin-top: 1rem !important;
}
.notification__minga__body {
  overflow-y: scroll;
  padding-left: 1rem;
  padding-right: 1rem;
}
.close__unmark {
  width: 0.5rem;
  height: 0.5rem;
  cursor: pointer;
  font-weight: 600;
}
.circle__unmark {
  width: 0.5rem;
  height: 0.5rem;
  background-color: var(--sidebar_secondory);
  border-radius: 50%;
}
