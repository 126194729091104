.mainEDITArea__PArent {
  background: var(--pure);
  border-radius: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  position: relative;
}

.mainEDITArea__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.mainDirArea_L_WIdth {
  width: 30%;
}

.mainDirArea_R_WIdth {
  width: 65%;
}

.mainEDITArea__BrandUpload_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mainEDITArea__BrandUpload {
  background-image: linear-gradient(
    90deg,
    var(--sidebar_secondory) 0%,
    var(--sidebar) 100%
  );
  border-radius: 0.5rem;
  /* height: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 5rem;
  cursor: pointer;
}

.mainEDITArea__BrandUploadInput {
  font-size: 1.5rem;
  border: none;
  outline: none;
  color: var(--headingBlue);
  width: 90%;
  padding-left: 1rem;
}

.mainEDITArea__BrandUploadInput::placeholder {
  font-size: 2rem;
  border: none;
  outline: none;
  color: var(--headingBlue);
  padding-left: 1rem;
}

.mainEDITArea__titleUploadInput {
  font-size: 1.5rem;
  border: none;
  outline: none;
  color: var(--headingBlue);
  width: 90%;
}

.mainEDITArea__titleUploadInput::placeholder {
  font-size: 1.5rem;
  border: none;
  outline: none;
  color: var(--headingBlue);
  padding-left: 1rem;
}

.mainEDITArea__Header_right {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.mainEDITArea__SPonserBLockCharity {
  display: flex;
}

.mainEDITArea__middle {
  display: flex;
  justify-content: space-between;
}

.mainEDITArea_middle_Asset {
  height: 100%;
  background-image: linear-gradient(
    90deg,
    var(--sidebar_secondory) 0%,
    var(--sidebar) 100%
  );
  margin-top: 1rem;
  border-radius: 1rem;
  height: 21rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;
}

.mainEditAreaMiddleRight__Lft__r1 {
  display: flex;
  align-items: flex-start;
}

.mainEDITArea__middle {
  margin-top: 1rem;
}

.mainEditAreaMiddleRight__Lft__r2 {
  margin-top: 1rem;
}

.mainEditAreaMiddleRight__Lft__r3 {
  margin-top: 1rem;
  display: flex;
  align-items: flex-start;
}

.mainEditAreaMiddleRight__Lft__r4 {
  margin-top: 1rem;
}

.mainEditAreaMiddleRight__Lft__r5 {
  margin-top: 1rem;
  display: flex;
  align-items: center; /* Change to center */
  flex-direction: column;
  text-align: center;
}
.mainEditAreaMiddleRight__Lft__r5 .ant-dINPUT {
  align-self: flex-start; /* Align TextArea to flex-start */
}

.w-50__MainEA {
  width: 30%;
}

#additionDedtao {
  display: inline-block;
  width: 70%;
}

.mainEditAreaMiddleRight__Lft__r6 {
  margin-top: 1rem;
}

.mainEDITArea_middle_right {
  display: flex;
  align-items: center;
}

#tags {
  width: 95% !important;
  align-self: flex-start;
}

.minEditAreaBounti__DESC_TExt {
  width: 94.5%;
}

.hashtagsMulti {
  width: 95% !important;
}

#additionDedtao {
  width: 95%;
}

#msafou {
  width: 95%;
}

.mainEditArea__bottom_L {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 1rem;
}

.mainEditArea__bottomWarpper {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.maiEditArea_assetBox {
  width: 31.3%;
  height: 6rem;
  background: var(--background);
  border-radius: 1rem;
}

.mainEDitArea__SaveBTN {
  position: absolute;
  bottom: 0;
  right: 0;
  background: var(--orange);
  color: var(--pure);
  border: none;
  outline: none;
  padding: 1rem;
  font-size: 1.2rem;
  border-end-end-radius: 1rem;
  border-top-left-radius: 1rem;
}

.bountititle {
  font-size: 1.8em;
  font-weight: 100;
  display: flex;
  margin-left: 1rem;
  align-items: flex-end;
}

.mainEditAreaMiddleRight__Lft {
  width: 85%;
}

.label__MAinAreaEdit {
  font-size: 1.1rem;
}

.mainEditArea__additionalBTN {
  margin-top: 1rem;
}

.mainEditArea__additionalBTN > button {
  background-color: var(--orange);
  color: var(--pure);
  border: none;
  padding: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 2rem;
  font-size: 1rem;
  cursor: pointer;
}

.mainEditAreaMiddleRight__Lft__r3__nOcollapse {
  width: 100.3%;
}

.mainEditAreaMiddleRight__Lft__r3__collapse {
  width: 100.4%;
}

.reward_uploader__ {
  display: flex;
  align-items: center;
}

.reward_uploader__controller {
  margin-left: 1rem;
}

.multipleSelectWith__Collapse {
  width: 97% !important;
}

.multiSelectContainer {
  width: 93% !important;
}

.multipleSelectWith__Collapse {
  width: 95% !important;
}

.multipleSelectWith__Sidebar_hash {
  width: 95% !important;
}

.multipleSelectWith__Collapse_hash {
  width: 95% !important;
}
.ustInput:focus {
  background-color: var(--inputFocus) !important;
}
.reward_edit__card__bottom{
  /* background-color: var(--sidebar); */
  background-color: var(--pure);
  height: 80%;
  width: 100%;
  border-radius: 1rem;
  border: 3px solid var(--collapseSubMenu);
}
.reward__hover__card__terms_and_condition_edit{
  width: 95%;
  margin: auto;
  padding-top: 1rem;
  padding-bottom: 1rem;
  height: 80%;
  overflow: auto;
  scrollbar-width: thin;
}
.reward__hover__card__terms_and_condition_edit::-webkit-scrollbar {
  width: 0.5rem !important;
}
.reward__hover__card__terms_and_condition_edit::-webkit-scrollbar-thumb {
  background: var(--orange);
  border-radius: 0.5rem;
}
.reward__hover__card__terms_and_condition_edit::-moz-scrollbar {
  width: 0.5rem !important;
}

.reward__hover__card__terms_and_condition_edit::-moz-scrollbar-thumb {
  background: var(--orange);
  border-radius: 0.5rem;
}
.reward__hover__card__terms_main_heading_edit h1 {
  color: var(--pure);
  font-size: 1.5rem;
}
.reward__hover__card__terms_main_heading_edit h2 {
  color: var(--pure);
  font-size: 1.1rem;
}
.terms_edit_close {
  cursor: pointer;
  color: var(--dark_2);
  font-size: 1.5rem;
}
.terms_edit_close p {
  margin-left: 95%;
}