.home__bountycard {
  background: var(--pure);
  border-radius: 1rem;
  padding: 1rem;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  width: 22.5rem;
  /* height: 54.5rem; */
  margin-top: 1.5rem;
  margin-right: 1rem;
}
@media screen and (max-width: 1452px) and (min-width: 1366px) {
  .home__bountycard {
    background: var(--pure);
    border-radius: 1rem;
    padding: 1rem;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    width: 21rem;
    /* height: 60rem; */
    margin-top: 1.5rem;
    margin-right: 1rem;
  }
}

.home__bounty__main_PN {
  font-size: 1.6em;
  font-weight: 100;
  color: var(--headingBlue);
}
.home__bounty__header_avtars {
  width: 54%;
}
.home__bounty__header_avtars_text {
  margin-top: 0.4rem;
  color: var(--grayShade1);
  width: 157%;
}
.home_bounty_avt_tx_high {
  color: var(--headingBlue);
}
.home__bounty__main {
  margin-top: 1.5rem;
}
.home__bounty__main__hash {
  color: var(--orange) !important;
  font-weight: 600;
  margin-top: 0.5rem;
}
.home__bounty__main__description {
  margin-top: 0.5rem;
  font-size: 1.1rem;
  color: var(--paragraphText);
}
.home__bounty__main__banner {
  margin-top: 1rem;
  margin-bottom: 1rem;
  /* height: 21rem; */
  /* background-color: var(--sidebar_secondory); */
  /* border-radius: 1rem; */
  /* box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.home__bounty__main__banner > img {
  width: 100%;
  /* height: 100%; */
  height: auto;
  border-radius: 1rem;
}
.home__bounty__reward {
  margin-top: 1rem;
}
.homeBountyCard__reward {
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
}
.homeBountyCard__rewardBlue {
  border-radius: 0.5rem;
  background: var(--sidebar_secondory);
  width: 3rem;
  height: 3rem;
}
.homeBountyCard__rewardName {
  color: var(--sidebar_secondory);
  /* margin-left: 1rem; */
  width:50%;
  font-weight: 500;
}
.relavancy_button {
  color: var(--pure);
  background: var(--sidebar) !important;
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  outline: none;
  padding: 1rem;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  border-bottom-left-radius: 1rem;
  border-top-right-radius: 1rem;
  font-weight: 500;
}
.delete_relavancy_button {
  color: var(--pure);
  background: var(--sidebar) !important;
  position: absolute;
  bottom: 0;
  right: 0;
  border: none;
  outline: none;
  padding: 1rem;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  border-top-left-radius: 1rem;
  border-end-end-radius: 1rem;
  font-weight: 500;
}

/* slider ad css */
.slider-container {
  position: relative;
}
.login__dialog__main__wrapper {
  display: flex;
  position: relative;
  border-radius: 1rem;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  margin-top: 6rem;
}

.slider {
  display: flex;
  transition: transform 0.5s ease;
}

.slide {
  flex: 0 0 100%;
  max-width: 100%;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.slide.active {
  opacity: 1;
}

.slider-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  cursor: pointer;
}

.slider-button.prev {
  left: 0;
}

.slider-button.next {
  right: 0;
}

@media screen and (min-width: 250px) and (max-width: 510px) {
  .home__bountycard {
    background: var(--pure);
    border-radius: 1rem;
    padding: 1rem;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    width: 17.5rem !important;
    margin-top: 1.5rem;
    margin-right: 1rem;
  }
}