.adView__dashboard {
  position: relative;
  width: 60rem;
  height: 50rem;
  background-color: var(--pure);
  border-radius: 1rem;
  padding-left: 1rem;
  padding-top: 2.5rem;
  padding-bottom: 1rem;
  padding-right: 1rem;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.adView__dashboard__close {
  position: absolute;
  top: -2rem;
  right: -0.5rem;
  cursor: pointer;
  padding: 0.5rem 0.7rem 0.5rem 0.7rem;
  /* border-radius: 1rem; */
  /* background: var(--pure); */
  font-weight: bold;
} 

.adframe {
  position: relative;
  width: 100%;
  height: 100%;
}

.adframe__md {
  width: 100%;
  height: 100%;
  object-fit: contain;
  /* margin-top: 4rem; */
}

.watch-later-icon {
  position: absolute;
  top: 1rem; /* Adjust top position as needed */
  left: 1rem; /* Adjust left position as needed */
  color: #ffffff; /* Adjust color as needed */
  font-size: 1.5rem; /* Adjust font size as needed */
  cursor: pointer;
}
@media screen and (min-width: 1100px) and (max-width: 1366px) {
  .adView__dashboard {
    position: relative;
    width: 40rem;
    height: 30rem;
    background-color: var(--pure);
    border-radius: 1rem;
    padding-left: 1rem;
    padding-top: 2.5rem;
    padding-bottom: 1rem;
    padding-right: 1rem;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
}