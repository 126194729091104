.main_wrapper_c1 {
  background-color: var(--pure);
  /* width: 71em; */
  border-radius: 1em;
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding-top: 1em;
  padding-bottom: 1em;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  position: relative;
}

.brand_c1 {
  width: 5em;
  height: 5em;
  border-radius: 1em;
}

.brandName_c1 {
  font-size: 1.5em;
  margin-left: 0.5em;
  font-weight: 400;
}

.left_c1 {
  /* width: 20em; */
  width: 30%;
  margin-left: 1em;
}

.three {
  display: flex;
  justify-content: space-between;
  margin-top: 1em;
}

.right_c1 {
  /* width: 47em; */
  width: 70%;
  margin-left: 3rem;
}

.avtar_heading_c1 {
  display: inline-block;
  margin-bottom: 1em;
}

.avtar_grp_c1 {
  display: flex;
  align-items: flex-end !important;
  margin-top: 1rem !important;
}

.numberOfEngage_C1 {
  margin-left: 1em;
  font-size: 1em;
}
.sponserWraper_c1 {
  display: flex;
}
.sponserSlider_c1 {
  /* background: var(--dark); */
  width: 12em;
  height: 5em;
  border-radius: 1em;
  margin-right: 1em;
  margin-top: 0.5em;
}

.charitySlider_c1 {
  background: var(--pink);
  width: 5em;
  height: 5em;
  border-radius: 1em;
  margin-right: 1em;
  margin-top: 0.5em;
}
.topRight {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.grid_c1 {
  margin-top: 1.5em;
}
.mainImg_c1 {
  width: 20em;
  /* height: 20em; */
  height: auto;
  border-radius: 1em;
  object-fit: cover;
}

.img_c1 {
  width: 6em;
  height: 6em;
  border-radius: 1em;
  object-fit: cover;
}
.main_c1 {
  padding-top: 1em;
  padding-bottom: 1em;
}
.bountititle {
  font-size: 1.8em;
  font-weight: 100;
}
.tags_c1 {
  display: flex;
}
.hashtag {
  color: var(--orange);
  font-weight: 500;
  font-size: 1rem;
  margin-left: 0.5em;
}
.hashtagFirst {
  margin-left: 0 !important;
}
.bountiTitleBar_c1 {
  display: flex;
  justify-content: space-between;
  padding-right: 1em;
  width:90%;
}
.badge {
  background: none !important;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  color: var(--orange) !important;
}
.icons_c1 {
  color: var(--orange) !important;
  cursor: pointer;
}
.bountiDescription_c1 {
  margin-top: 1em;
  display: flex;
  justify-content: space-between;
  /* width:90%; */
}
.bText {
  font-size: 1em;
  color: var(--paragraphText);
  line-height: 22px;
}
.desc_c1 {
  width: 30em;
  text-align: left;
}

.ust_card {
  background-color: var(--sidebar);
  border-start-start-radius: 1em;
  border-end-start-radius: 1em;
  width: 8em;
  padding: 0.3em;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1em;
}
.ust_card > div > p,
.ust_card > p {
  font-size: 0.8em;
  color: var(--sidebar_fade);
}
.reward_carousel {
  width: 18em !important;
  margin: 0em !important;
}

.rewardSld {
  background: var(--sidebar_secondory);
  width: 5rem;
  height: 5rem;
  border-radius: 1em;
}

.rewardName {
  font-size: 0.9em;
  color: var(--sidebar_secondory);
  font-weight: 500;
}
.arrowReward {
  color: var(--orange);
  font-size: 3.5em !important;
  margin-top: 1.2rem;
  cursor: pointer;
}

.submit_btn {
  background: var(--orange);
  border: none;
  color: var(--pure);
  padding: 1em;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 15em;
  font-size: 1em;
  font-weight: 400;
  cursor: pointer;
  border-end-end-radius: 1em;
  border-top-left-radius: 1em;
}

.bounty_commet_header_c2 {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}

.header_btns {
  padding: 1em;
  width: 17em;
  border: none;
  background: var(--pure);
  font-size: 1.1em;
  cursor: pointer;
}
.bounty_commet_header_c2 {
  width: fit-content;
}
.header_btn1 {
  border-top-left-radius: 1em;
  border-end-start-radius: 1em;
}
.header_btn2 {
  border-top-right-radius: 1em;
  border-end-end-radius: 1em;
}
.header_active {
  background-color: var(--sidebar_secondory);
  color: var(--pure);
}

.commentCard {
  height: 8em;
  width: 100%;
  background: var(--pure);
  border-radius: 1em;
  display: flex;
}

.commentImage_c2 {
  width: 8em;
  height: 8em;
  border-top-left-radius: 1em;
  border-end-start-radius: 1em;
}

.sub_user {
  width: 2em;
  height: 2em;
  border-radius: 1em;
}
.comment_card_main {
  padding: 1em;
  display: flex;
  justify-content: space-between !important;
  width: 63em;
}

.submissionTitle {
  margin-top: 1em;
  font-size: 1.2em;
}
