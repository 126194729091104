.login___left {
  width: 25%;
}

.login___right {
  width: 72%;
  margin-left: 5rem;
}

.login___header {
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  align-items: center;
  height: 9rem;
}

.login__header_tab {
  display: flex;
  filter: drop-shadow(0px 3px 3.5px rgba(0, 0, 0, 0.16));
  height: 3.313rem;
}

.auth__tab {
  text-transform: capitalize;
  width: 10rem;
  color: #262626;
  padding: 1rem;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  font-size: 1.2rem;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: var(--pure);
  font-size: 1rem;
}

.auth__tab__left {
  border-top-left-radius: 0.5rem;
  border-end-start-radius: 0.5rem;
}

.auth__tab__right {
  border-top-right-radius: 0.5rem;
  border-end-end-radius: 0.5rem;
}

.auth__login {
  color: #262626;
  margin-left: 26rem;
  border-radius: 0.5rem;
  text-transform: capitalize;
  width: 6.75rem;
  padding: 1rem;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  font-size: 1rem;
  border: none;
  outline: none;
  cursor: pointer;
  filter: drop-shadow(0px 3px 3.5px rgba(0, 0, 0, 0.16));
  background-color: #ffffff;
  margin-right: 1rem;
  height: 3.313rem;
}
.auth_btn_2 {
  color: #262626;
  border-radius: 0.5rem;
  text-transform: capitalize;
  padding: 1rem;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  font-size: 1rem;
  border: none;
  outline: none;
  cursor: pointer;
  filter: drop-shadow(0px 3px 3.5px rgba(0, 0, 0, 0.16));
  background-color: #ffffff;
  height: 3.313rem;
}

.auth__signup {
  color: var(--pure);
  border-radius: 0.5rem;
  width: 6.75rem;
  height: 3.313rem;
  padding: 1rem;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  font-size: 1rem;
  border: none;
  outline: none;
  cursor: pointer;
  filter: drop-shadow(0px 3px 3.5px rgba(0, 0, 0, 0.16));
  background-color: var(--orange);
  margin-right: 1rem;
}

.auth__act {
  background-color: var(--sidebar_secondory);
  color: var(--pure);
}

.login___header__right {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.login__header_sponserOfMonth__image > img {
  width: 11rem;
  height: 3.6rem;
  margin-top: 0.4rem;
  border-radius: 0.5rem;
}

.login__header_sponserOfMonth__label {
  font-size: 0.625rem;
  color: var(--sidebar_secondory);
  font-weight: 400;
}

.login__hero__section {
  text-align: center;
  margin-top: 4rem;
}
@media screen and (max-width: 1440px) {
  .auth__login {
    color: #262626;
    margin-left: 5rem;
    border-radius: 0.5rem;
    text-transform: capitalize;
    width: 6.75rem;
    padding: 1rem;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    font-size: 1rem;
    border: none;
    outline: none;
    cursor: pointer;
    filter: drop-shadow(0px 3px 3.5px rgba(0, 0, 0, 0.16));
    background-color: #ffffff;
    margin-right: 1rem;
    height: 3.313rem;
  }
}
.login__hero__section h1 {
  font-size: 3.313rem;
  color: var(--loginHeroHeading);
  font-weight: 500;
}

.login__hero__section h1 > span {
  color: var(--orange);
}

.login__hero__section p {
  margin-top: 1rem;
  font-size: 1.813rem !important;
  color: var(--grayShade2);
}

.how__it__work {
  width: 80%;
  margin: 4rem auto;
}

.how__it__work__heading {
  font-size: 3.313rem;
  color: var(--loginHeroHeading);
  text-align: center;
  font-weight: 500;
}

.how__it__work__flexBox {
  margin-top: 3rem;
}
.how__it__work__flexBox___right h1 {
  color: var(--loginHeroHeading);
  margin-top: 2rem;
}
.how__it__work__flexBox__part h1 {
  color: var(--loginHeroHeading);
  margin-top: 2rem;
}
.how__it__work__flexBox___right p {
  color: var(--paragraphText);
  font-size: 1.2rem;
  line-height: 1.2rem;
  font-weight: 400;
  width: 80%;
  margin-top: 2rem;
}
.how__it__work__flexBox__part p {
  color: var(--paragraphText);
  font-size: 1.2rem;
  line-height: 1.2rem;
  font-weight: 400;
  width: 80%;
  margin-top: 2rem;
}
.how__it__work__flexBox__part {
  width: 50%;
}
.how__it__work__flexBox___left {
  width: 70%;
}
.how__it__work__flexBox___right {
  display: flex;
  flex-direction: column;
  align-items: end;
}
.how__it__work__flexBox___right > div {
  width: fit-content;
}
.how__it__work__flexBox2 {
  flex-direction: row-reverse;
}
.wrong__captcha {
  background: none !important;
  border: none !important;
  bottom: 70px !important;
}
@media screen and (min-width: 1146px) and (max-width: 1345px) {
  .login___left {
    width: 7%;
  }
}
@media screen and (min-width: 1045px) and (max-width: 1145px) {
  .login___left {
    width: 15%;
  }
  .auth__tab {
    text-transform: capitalize;
    width: 8rem;
    color: #262626;
    padding: 1rem;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    font-size: 1.2rem;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: var(--pure);
    font-size: 1rem;
  }
  .auth__act {
    background-color: var(--sidebar_secondory);
    color: var(--pure);
  }
  .auth__tab__left {
    border-top-left-radius: 0.5rem;
    border-end-start-radius: 0.5rem;
  }

  .auth__tab__right {
    border-top-right-radius: 0.5rem;
    border-end-end-radius: 0.5rem;
  }
}
/* Mobile View */
@media screen and (min-width: 250px) and (max-width: 510px) {
  .login___left {
    width: 5%;
  }
  .login___left img {
    width: 5rem;
  }
  .login__hero__section {
    text-align: center;
    margin-top: 2rem;
  }
  .login__hero__section h1 {
    font-size: 1.3rem;
    color: var(--loginHeroHeading);
    font-weight: 500;
  }
  .login__hero__section h1 > span {
    color: var(--orange);
  }
  .login__hero__section p {
    margin-top: 1rem;
    font-size: 1rem !important;
    color: var(--grayShade2);
  }
  .how__it__work {
    width: 100%;
    margin: 4rem auto;
  }
  .how__it__work__heading {
    font-size: 2rem;
    color: var(--loginHeroHeading);
    text-align: center;
    font-weight: 500;
  }

  /* .login__header_tab {
    filter: drop-shadow(0px 3px 3.5px rgba(0, 0, 0, 0.16));
    height: 3.313rem;
  }

  .auth__tab {
    text-transform: capitalize;
    width: 4.5rem;
    color: #262626;
    padding: 1rem;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    font-size: 1.2rem;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: var(--pure);
    font-size: 0.8rem;
  }
  .login___right {
    width: fit-content;
  }
  .auth__tab__left {
    border-top-left-radius: 0.5rem;
    border-end-start-radius: 0.5rem;
  }

  .auth__tab__right {
    border-top-right-radius: 0.5rem;
    border-end-end-radius: 0.5rem;
  }
  .auth__act {
    background-color: var(--sidebar_secondory);
    color: var(--pure);
  } */
}
