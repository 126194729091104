.adView__dashboard {
  position: relative;
  width: 60rem;
  height: 50rem;
  background-color: var(--pure);
  border-radius: 1rem;
  padding: 1rem;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.adView__dashboard__close {
  position: absolute;
  top: 2rem;
  right: 2.5rem;
  cursor: pointer;
  padding: 0.5rem 0.7rem 0.5rem 0.7rem;
  /* border-radius: 1rem; */
  /* background: var(--pure); */
  font-weight: bold;
}
:where(.adframe) {
  width: 100%;
  height: 100%;
}
:where(.adframe) :where(.adframe__md) {
  width: 100%;
  height: 100%;
}
