.global__footer {
  background: var(--footer);
  height: 7.188rem;
  width: 100%;
  padding: 2rem;
  position: relative;
}
.language__selector {
  background-color: var(--pure);
  width: 13rem;
  padding: 1rem;
  border-radius: 1rem;
  filter: drop-shadow(0px 3px 3.5px rgba(0, 0, 0, 0.16));
  cursor: pointer;
}
.language__selector__dropdown {
  position: absolute;
  top: -6rem;
  width: 13rem;
  padding: 1rem;
  filter: drop-shadow(0px 3px 3.5px rgba(0, 0, 0, 0.16));
}
.lan_footer {
  display: flex;
  justify-content: space-between;
}
.footer__middle{
    text-align: center;
    font-weight: bold;
}