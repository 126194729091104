.annoucemnet__popup {
  padding: 1rem;
  /* width: 58.813rem; */
  width: 70rem;
  /* height: 41.688rem; */
  border-radius: 1rem;
  background: var(--pure);
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  position: relative;
}
.minga__popup {
  backdrop-filter: blur(1rem);
}
.annoucement__popup__header__engage {
  margin-top: 1rem;
  display: flex;
}
.social__cursor {
  cursor: pointer;
}
.annoucement__popup__header__time {
  text-align: end;
}
.likehere {
  margin-left: 0.5rem;
}
.commenthere {
  margin-left: 0.5rem;
}
.annoucement__popup__body {
  margin-top: 2rem;
}
.anc__subbmission__tagline {
  display: inline-block;
  color: var(--orange);
  margin-top: 0.4rem;
}
.anc__subbmission__name {
  color: var(--headingBlue);
  font-weight: 500;
  font-size: 1.5rem;
}

.annoucement__popup__body__left {
  width: 50%;
}
.anc__subbmission__desc {
  font-size: 1.1rem;
  color: var(--grayShade2);
}
.annoucement__popup__header {
  width: 90%;
  margin: auto;
}
.annoucement__popup__body {
  width: 90%;
  margin: 3rem auto;
}
.annoucement-slide {
  width: fit-content !important;
}
.annoucement-slide {
  width: 30% !important;
  height: 5rem !important;
  border-radius: 0.625rem;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
}
.ancmnt__main__media__frame {
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
  border-radius: 1rem;
  width: 25rem;
  height: 25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.annoucement__swiper {
  width: 23rem !important;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.annoucement__media__view {
  text-align: center !important;
}
.ancmnt__main__media__swiper {
  width: 25rem !important;
}
.annoucement-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.anc__subbmission__desc {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  border-radius: 0.5rem;
}
.anc__subbmission__desc:focus {
  background: var(--inputFocus);
  color: var(--pure);
  padding: 1rem;
}
.close__anoucement_btn {
  background-color: var(--pure);
  padding: 0.5rem;
  font-size: 1.2rem;
  font-weight: bold;
  border-radius: 0.5rem;
  cursor: pointer;
  position: absolute;
  top: 0rem;
  right: 1rem;
  /* transform: scale(0);
  transition: all 0.2s; */
}
.announcement__popup__SaveBTN {
  position: absolute;
  bottom: 0;
  right: 0;
  background: var(--orange);
  color: var(--pure);
  border: none;
  outline: none;
  padding: 1rem;
  font-size: 1.2rem;
  border-end-end-radius: 1rem;
  border-top-left-radius: 1rem;
  cursor: pointer;
  margin-top: 3rem;
}
.annoucemnet__popup:hover .close__anoucement_btn {
  transform: scale(1);
}
.overRight__submissionSwiper {
  width: 42rem !important;
}

.submission__rewrdTitle {
  color: var(--sidebar_secondory);
  margin-top: 0.5rem;
  margin-left: 0.5rem;
}
.sub_RE_slide {
  width: 30% !important;
}
.Submission__POPUpHeading {
  color: var(--sidebar_secondory);
  font-size: 1.4rem;
  padding-left: 2.4rem;
}
.Submission__POPUpHeading2 {
  color: var(--sidebar_secondory);
  font-size: 1.4rem;
}
.submit__button_SubmisnReawrd {
  margin-left: 2.4rem;
}
.isReviewed__REawrd {
  margin-left: 2.4rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
}
.isReviewed__REawrd > p {
  margin-left: 1rem;
}
.reward__name__submision {
  color: var(--sidebar_secondory);
  text-align: center;
}
.isReviwed__reward > .ant-checkbox::before {
  content: "" !important;
}
.icon_thumbup{
  color: var(--orange) !important;
  cursor: pointer;
}