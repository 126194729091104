.confirm__dialog__main__wrapper {
  display: flex;
  position: relative;
  border-radius: 1rem;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  margin-top: 6rem;
  height: fit-content;
}

.confirm__dialog__left__side {
  width: 27.438rem;
  height: 45.125rem;
  border-top-left-radius: 1.25rem;
  border-bottom-left-radius: 1.25rem;
  background-color: #f4f5f6;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
}

.confirm_heading {
  width: 23rem;
  color: #00537c;
  font-size: 1.938rem;
  font-weight: 400;
  margin-bottom: 3rem;
}

.confirm_text {
  width: 22rem;
  height: 3.313rem;
  font-size: 1.125rem;
  line-height: 1.688rem;
  color: #494949;
  font-weight: 700;
}

.confirm_text_section {
  border: 3px solid var(--pure);
  width: 3rem;
  padding: 0.2rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  padding-bottom: 0;
  border-radius: 0.3rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-left: 1.5rem;
}

.confirm__social_section {
  border: 1.8px solid #00537c;
  width: 3rem;
  padding: 0.2rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  padding-bottom: 0;
  border-radius: 0.8rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-left: 1.5rem;
}

.detail__text {
  width: 20.438rem;
  height: 5.375rem;
  font-size: 1rem;
  line-height: 1.75rem;
  color: #262626;
  font-weight: 400;
  margin-top: 2.5rem;
}

.confirm_btn__dialog {
  margin-top: 2rem;
  background: var(--orange);
  color: var(--pure);
  border: none;
  width: 50%;
  height: 3rem;
  font-size: 1rem;
  font-weight: 400;
}

.confirm_btn__dialog:hover {
  color: var(--pure) !important;
}

.click__here {
  font-size: 0.75rem;
  line-height: 1.188rem;
  color: #262626;
  font-weight: 400;
  text-align: left;
  margin-top: 4rem;
  margin-bottom: 4.5rem;
}

.check__bounties__text {
  width: 327px;
  height: 38px;
  font-size: 16px;
  line-height: 28px;
  color: #262626;
  font-weight: 400;
}

.click__here span {
  font-size: 0.75rem;
  line-height: 1.188rem;
  color: var(--orange);
  font-weight: 500;
  text-align: left;
  margin-top: 2rem;
}

.confirm__INPUT__dialog {
  margin-top: 1rem;
}

.confirm__INPUT__dialog > label {
  color: #00537c;
  font-size: 1rem;
  line-height: 1.125rem;
  font-weight: 700;
}

.confirm__INPUT__input__dialog {
  margin-top: 0.5rem;
  height: 2.5rem;
  outline: none;
}

.confirm__dialog__right__side {
  width: 27.25rem;
  height: 45.125rem;
  border-top-right-radius: 1.25rem;
  border-bottom-right-radius: 1.25rem;
}

.confirm__dialog__right__side img {
  border-top-right-radius: 1.25rem;
  border-bottom-right-radius: 1.25rem;
  width: 27.25rem;
  height: 45.125rem;
}

.confirm__checkbox__dialog {
  margin-top: 0.7rem;
}

.confirm__checkbox__dialog label {
  font-size: 0.75rem;
  line-height: 1.188px;
  color: #262626;
  font-weight: 400;
}

.confirm__checkbox__dialog input {
  margin-right: 0.2rem;
}

.confirm__dialog__close {
  position: absolute;
  right: 3rem;
  top: 3rem;
  cursor: pointer;
  font-size: 1.5rem;
  color: #ffffff;
  background: none;
  border: none;
  outline: none;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  font-weight: 400;
}

.slider__box {
  width: 337px;
  height: 136px;
  border-radius: 15px;
  background-color: #427baa;
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
}

.slider__img {
  width: 104px;
}

.slider__img img {
  width: 104px;
  height: 104px;
  border-radius: 10px;
  margin: 1rem;
}

.slider__text {
  width: 200px;
  height: 104px;
  margin-top: 0.5rem;
  text-align: right;
  flex-direction: column;
}

.slider__text__1 {
  height: 27px;
  font-size: 16px;
  line-height: 38px;
  color: #ffffff;
  font-weight: 400;
  text-align: right;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.slider__text__2 {
  font-size: 16px;
  line-height: 18px;
  color: #e98326;
  font-weight: 700;
  text-align: right;
  margin-right: 1rem;
  margin-bottom: 1.3rem;
}

.slider__text__3 {
  text-align: right;
  margin-right: 1rem;
  font-size: 13px;
  line-height: 18px;
  color: #ffffff;
  font-weight: 400;
}

.slider__text__4 {
  text-align: right;
  margin-right: 1rem;
  margin-bottom: 1rem;
  font-size: 13px;
  line-height: 18px;
  color: #ffffff;
  font-weight: 400;
}

.prev__icon {
  width: 9px;
  height: 12px;
  background-color: #e98326;
}

.next__icon {
  width: 9px;
  height: 12px;
  background-color: #e98326;
}

.register_confirmation_slide {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}
.register_confirmation_slide > .arrows {
  margin-top: 0.6rem;
}
.register_confirmation_slide > .arrows__prev {
  margin-right: 0.5rem;
}

.arrows__next{
  margin-left: 0.5rem;
}
