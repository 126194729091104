.home__advertise__card {
  width: 22.5rem;
  /* height: 25rem; */
  background: var(--pure);
  border-radius: 1rem;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  margin-top: 1.5rem;
}
.homePinButton__ad {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.home__advertise__content {
  padding: 1rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: none;
  z-index: 9;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.home__ad__brandLogo {
  background-color: var(--pure);
  width: 3.875rem;
  height: 3.75rem;
  border-radius: 0.6rem;
}
.home__ad__brand {
  color: var(--pure);
  font-size: 1.5rem;
  font-weight: 400;
  margin-left: 1rem;
}
.home__ad__bottomText {
  font-weight: 700;
  font-size: 1.375rem;
  color: var(--pure);
}
.homePinButton {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  left: 91%;
  top: 8%;
  position: absolute;
}
.ant-progress-text {
  color: var(--pure) !important;
  font-weight: bold;
}

@media screen and (min-width: 250px) and (max-width: 510px) {
  .home__advertise__card {
    width: 17.5rem !important;
    /* height: 25rem; */
    background: var(--pure);
    border-radius: 1rem;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    margin-top: 1.5rem;
  }
}
