.p_filter {
  position: relative;
}
.p_filter__dropdown {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  position: absolute;
  top: 3.7rem;
  left: 0;
  width: 100%;
  background: var(--pure);
  z-index: 999;
  border-radius: 1rem;
  padding: 1rem;
  line-height: 2rem;
}
.p_filter__dropdown > li {
  list-style-type: none !important;
  font-size: 1.2rem;
  text-align: center;
  padding: 1rem;
  border-radius: 1rem;
}
.p_filter__dropdown > li:hover {
  background-color: var(--sidebar_secondory);
  color: var(--pure);
}
.p_filter__dropdown > li.active {
  background-color: var(--sidebar_secondory);
  color: var(--pure);
}
.P__search {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}
.P__pinSection {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}
.p_filter {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}
