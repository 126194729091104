.annoucement__card__main {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  background: var(--pure);
  border-radius: 1rem;
  padding: 1rem;
}
.annoucement__card__mainLeftCK {
  color: var(--dark_2);
  font-size: 1rem;
  margin-top: 0.8rem;
}
