.fluid {
  width: 100%; /* Fluid width based on percentage */
  max-width: 3024px;
  min-width: 1800px;
  /* margin: 0 auto; */
  /* margin-bottom: auto; */
  /* margin-top: auto; */
  /* margin-right: auto; */
  height: 100vh;
  background: var(--background);
  position: relative;
}

.main {
  width: calc(100% - 15em);
  padding-top: 2em;
  padding-bottom: 2em;
  transition: all 0s !important;
}

.mainInner {
  width: 94%;
  margin: auto;
}

.mainWrapper {
  margin-top: 2em;
}

@media screen and (max-width: 1542px) {
  .fluid {
    justify-content: space-between !important;
  }
  .main {
    width: 92rem !important;
    padding-top: 2em;
    padding-bottom: 2em;
    transition: all 0s !important;
    margin-left: 0 !important;
  }
}

@media screen and (max-width: 1490px) {
  .main {
    width: 88rem !important;
    padding-top: 2em;
    padding-bottom: 2em;
    transition: all 0s !important;
    margin-left: 0 !important;
  }
  .mainInner {
    width: 99%;
    margin: auto;
  }
}
